<script>
export default {
	name: 'FlightSchedule',
	inheritAttrs: false,
};
</script>

<script setup>
import yup from 'mh-yup';
import MobileSubpage from '~/components/BookingWidget/MobileSubpage.vue';
import useBookingWidgetSubpage from '~/logic/composables/useBookingWidgetSubpage.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import CTAButton from '~/aem-components/CTAButton.vue';
import ONDPicker from '~/components/BookingWidget/ONDPicker.vue';
import DateRangePicker from '~/components/BookingWidget/DateRangePicker.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import Tooltip from '~/components/Tooltip.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import axios from 'axios';
import dayjs from 'dayjs';

const props = defineProps({
	// modelValue: { type: String, default: '' },
	isInBookingWidget: { type: Boolean, default: false },

	flightScheduleDescription: { type: String, default: '' },
	flightScheduleToolTipLabel: { type: String, default: '' },
	flightScheduleToolTip: { type: String, default: '' },
	flightScheduleNote: { type: String, default: '' },
	flightScheduleButtonTitle: { type: String, default: '' },

	fromFieldLabel: { type: String, default: '' },
	fromMandatoryField: { type: Boolean, default: false },
	fromMandatoryFieldErrorMessage: { type: String, default: '' },

	toFieldLabel: { type: String, default: '' },
	toMandatoryFieldErrorMessage: { type: String, default: '' },
	toMandatoryField: { type: Boolean, default: false },

	dateMandatoryField: { type: Boolean, default: false },
	noResultMessage: { type: String, default: '' },

	departDateFieldLabel: { type: String, default: '' },
	returnDateFieldLabel: { type: String, default: '' },
	maxDaysFuture: { type: String, default: '' },

	resetButtonCtaText: { type: String, default: '' },
	onewayCtaText: { type: String, default: '' },
	doneCtaText: { type: String, default: '' },

	apiUrl: { type: String, default: '/bin/flight-schedule' },

	componentIdAA: { type: String, default: '' },
	
	// Prop for HiddenSeamlessBookingWidget component
	isHideInDesktop: { type: Boolean, default: false },
});
const emit = defineEmits([
	// 'update:modelValue',
]);

const rootEl = ref(null);
const formWrapper = ref(null);

const { isMobileSubpageVisible } = useBookingWidgetSubpage({
	hashKey: 'flight-schedule',
	rootEl,
});

const { pageProperties, isEditorMode } = useGlobalAEMState();
const country = pageProperties.value.rootCountry;
const language = pageProperties.value.rootLanguage;

const capitalizeString = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const handleSubmitValid = (values, actions) => {
	console.log('✅ handleSubmitValid values = ', values);

	formSubmission();
};
const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
};

const ondValueFrom = ref(null);
const ondValueTo = ref(null);


const dateRangeValueFrom = ref(null);
const dateRangeValueTo = ref(null);


const isBtnSubmitDisabled = computed(() => {
	dateRangeValueFrom.value; // eslint-disable-line
	dateRangeValueTo.value; // eslint-disable-line
	ondValueFrom.value; // eslint-disable-line
	ondValueTo.value; // eslint-disable-line
	if (!formWrapper.value) return true;
	return (
		!dateRangeValueFrom.value ||
		!ondValueFrom.value ||
		!ondValueTo.value ||
		Object.keys(formWrapper.value.errors).length !== 0
	);
});


const maxDateComputed = computed(() => {
	if (props.maxDaysFuture) {
		
		return `t+${props.maxDaysFuture}d`;
	}

	return null;
});

const inputDateHourFormat = (date, hour) => {
	/* 
	*	Return formatted date from 2022-12-23 to 202212231200
	*/
	return dayjs(date).hour(hour).format('YYYYMMDDHHmm');
};

const formSubmission = () => {
	const requestBody = {
		'departDate': inputDateHourFormat(dateRangeValueFrom.value, 12),
		...(dateRangeValueTo.value !== null ? {
			'returnDate': inputDateHourFormat(dateRangeValueTo.value, 12),
		} : {}),
		'origin': ondValueFrom.value.code,
		'destination': ondValueTo.value.code,
		'tripType': dateRangeValueTo.value === null ? 'O' : 'R',
		'regionLanguage': country && language ? `${country}-${capitalizeString(language)}` : '',
	};
	axios.post(props.apiUrl, requestBody)
		.then(({ data }) => {

			if (Object.keys(data).length !== 0) {

				const url = new URL(data.url);

				Object.keys(data.payload).forEach((key) => {
					url.searchParams.append(key, data.payload[key]);
				});

				// Redirect to Flight Schedule timetable DX page
				window.location.href = url.toString();
			}
		})
		.catch((error) => {
			console.error(error);
		});
};

</script>

<template>
<div
	v-if="!props.isHideInDesktop"
	ref="rootEl"
	class="FlightSchedule booking-widget-subcomponent"
	:class="{
		'isEditorMode': isEditorMode,
	}"
>
	<div class="generic-container">
		<h5 v-if="isEditorMode" class="title-editor-mode">
			<icon-fas-plane class="mr-2 fill-current inline-block" />
			<span>Booking Widget: Flight Schedule</span>
		</h5>
		
		<p v-html-sanitize="props.flightScheduleDescription" class="mb-6"></p>
		<div
			v-if="props.flightScheduleToolTipLabel && props.flightScheduleToolTip"
			class="check-in-tooltip flex items-center mb-6"
		>
			<Tooltip>
				<template #default>
					<icon-fas-circle-question class="fill-primary-blue-base" />
				</template>
				<template #mobile-title>
					{{ props.flightScheduleToolTipLabel }}
				</template>
				<template #tooltip-content>
					<p v-html-sanitize="props.flightScheduleToolTip"></p>
				</template>
			</Tooltip>
			<span class="ml-3">{{ props.flightScheduleToolTipLabel }}</span>
		</div>

		<FormWrapper
			ref="formWrapper"
			v-slot="{ errors }"
			class="form-container flex gap-6 xl:gap-3 xl:flex-wrap lg:flex-col"
			@submit-valid="handleSubmitValid"
			@submit-invalid="handleSubmitInvalid"
		>

			<div class="flex-grow lg:w-full">
				<ONDPicker
					v-model:modelValueFrom="ondValueFrom"
					v-model:modelValueTo="ondValueTo"
					nameFrom="flight-schedule-from"
					nameTo="flight-schedule-to"
					:requiredFrom="props.fromMandatoryField"
					:requiredTo="props.toMandatoryField"
					:requiredErrorMsgFrom="props.fromMandatoryFieldErrorMessage"
					:requiredErrorMsgTo="props.toMandatoryFieldErrorMessage"
					:labelTextFrom="props.fromFieldLabel"
					:labelTextTo="props.toFieldLabel"
					:noResultsText="props.noResultMessage"
					ondListIdentifier="FLIGHT_SCHEDULE"
				>
					<template #from-picker-mobile-title>
						<p>{{ props.fromFieldLabel }}</p>
					</template>
					<template #to-picker-mobile-title>
						<p>{{ props.toFieldLabel }}</p>
					</template>
				</ONDPicker>
			</div>
			<div class="w-4/10 lg:w-full">
				<DateRangePicker
					v-model:modelValueFrom="dateRangeValueFrom"
					v-model:modelValueTo="dateRangeValueTo"
					:textFieldAttrsFrom="{
						ariaLabel: props.departDateFieldLabel,
						placeholder: $t('Select a date'),
					}"
					:textFieldAttrsTo="{
						ariaLabel: props.returnDateFieldLabel,
						placeholder: $t('Select a date'),
					}"
					:maxDate="maxDateComputed"
					:disablePastDates="true"
					nameFrom="flightScheduleDeparture"
					nameTo="flightScheduleReturn"
					:requiredFrom="props.dateMandatoryField"
					:requiredTo="props.dateMandatoryField"
					:requiredErrorMsgFrom="props.dateMandatoryFieldErrorMessage"
					:requiredErrorMsgTo="props.dateMandatoryFieldErrorMessage"
					:labelReset="props.resetButtonCtaText ?? $t('Reset')"
					:labelDone="props.doneCtaText ?? $t('Done')"
					:labelOneWay="props.onewayCtaText ?? $t('One-way')"
				>
					<template #mobile-title>
						{{ $t('Select a date') }}
					</template>
				</DateRangePicker>
			</div>
			<div class="w-[max-content] flex-none xl:w-full">
				<CTAButton
					v-aa="[
						{
							clickName: props.flightScheduleButtonTitle,
							clickComponentType: 'Button',
							componentName: 'FlightSchedule',
							componentID: props.componentIdAA,
						},
						{
							name: props.flightScheduleButtonTitle,
							type: 'exit',
						},
					]"
					:sizeType="isMobileViewport ? 'small' : 'large'"
					:isSubmit="true"
					:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
					:disabled="isBtnSubmitDisabled"
					alignmentDesktop="right"
					class="lg:mx-auto lg:mt-3"
				>{{ props.flightScheduleButtonTitle }}</CTAButton>
			</div>
		</FormWrapper>

		<div class="flex mt-6">
			<div class="rounded-xl bg-primary-blue-extralight flex-grow px-5 py-4 flex">
				<div class="basis-6 flex-none mr-3 rtl:ml-3 rtl:mr-0 top-0.5 relative">
					<icon-fas-circle-info class="fill-primary-blue-base" aria-hidden="true" />
				</div>
				<div class="typo-body-text-2">
					<span v-html-sanitize="props.flightScheduleNote"></span>
				</div>
			</div>
		</div>
	</div>
</div>

<MobileSubpage
	:isVisible="isMobileSubpageVisible"
	class="text-white"
	style="--overlay-container-bg-color: var(--secondary-blue-extradark);"
	@update:is-visible="isMobileSubpageVisible = $event"
>

	<template #banner-title>
		<h1 class="text-center">Flight Schedule</h1>
	</template>

	<template #default>
		<div class="generic-container my-4">
			<p v-html-sanitize="props.flightScheduleDescription" class="mb-6 text-sm"></p>
			<div
				v-if="props.flightScheduleToolTipLabel && props.flightScheduleToolTip"
				class="check-in-tooltip flex items-center mb-6"
			>
				<Tooltip>
					<template #default>
						<icon-fas-circle-question class="fill-primary-blue-base" />
					</template>
					<template #mobile-title>
						{{ props.flightScheduleToolTipLabel }}
					</template>
					<template #tooltip-content>
						<p v-html-sanitize="props.flightScheduleToolTip"></p>
					</template>
				</Tooltip>
				<span class="ml-3 text-sm">{{ props.flightScheduleToolTipLabel }}</span>
			</div>
			
			
			<FormWrapper
				class="form-container flex gap-6 xl:gap-3 xl:flex-wrap lg:flex-col"
				@submit-valid="handleSubmitValid"
				@submit-invalid="handleSubmitInvalid"
			>
				<div class="flex-grow lg:w-full">
					<ONDPicker
						v-model:modelValueFrom="ondValueFrom"
						v-model:modelValueTo="ondValueTo"
						nameFrom="flight-schedule-from"
						nameTo="flight-schedule-to"
						:requiredFrom="true"
						:requiredTo="true"
						:labelTextFrom="props.fromFieldLabel"
						:labelTextTo="props.toFieldLabel"
						:noResultsText="props.noResultMessage"
						requiredErrorMsgFrom="Please select the departure destination"
						requiredErrorMsgTo="Please select the arrival destination"
						ondListIdentifier="FLIGHT_SCHEDULE"
					>
					</ONDPicker>
				</div>
				<div class="w-4/10 lg:w-full">
					<DateRangePicker
						v-model:modelValueFrom="dateRangeValueFrom"
						v-model:modelValueTo="dateRangeValueTo"
						:textFieldAttrsFrom="{
							ariaLabel: props.departDateFieldLabel,
							placeholder: $t('Select a date'),
						}"
						:textFieldAttrsTo="{
							ariaLabel: props.returnDateFieldLabel,
							placeholder: $t('Select a date'),
						}"
						:maxDate="maxDateComputed"
						:disablePastDates="true"
						nameFrom="flightScheduleDeparture"
						nameTo="flightScheduleReturn"
						:requiredFrom="props.dateMandatoryField"
						:requiredTo="props.dateMandatoryField"
						:requiredErrorMsgFrom="props.dateMandatoryFieldErrorMessage"
						:requiredErrorMsgTo="props.dateMandatoryFieldErrorMessage"
						:labelReset="props.resetButtonCtaText ?? $t('Reset')"
						:labelDone="props.doneCtaText ?? $t('Done')"
						:labelOneWay="props.onewayCtaText ?? $t('One-way')"
					>
						<template #mobile-title>
							{{ $t('Select a date') }}
						</template>
					</DateRangePicker>
				</div>
				<div class="w-[max-content] flex-none xl:w-full">
					<CTAButton
						v-aa="[
							{
								clickName: props.flightScheduleButtonTitle,
								clickComponentType: 'Button',
								componentName: 'FlightSchedule',
								componentID: props.componentIdAA,
							},
							{
								name: props.flightScheduleButtonTitle,
								type: 'exit',
							},
						]"
						:sizeType="isMobileViewport ? 'small' : 'large'"
						:isSubmit="true"
						:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
						alignmentDesktop="right"
						class="lg:mx-auto lg:mt-3"
					>{{ props.flightScheduleButtonTitle }}</CTAButton>
				</div>
			</FormWrapper>

			<div class="flex mt-6">
				<div class="rounded-xl bg-primary-blue-extralight flex-grow px-5 py-4 flex">
					<div class="basis-6 flex-none mr-3 rtl:ml-3 rtl:mr-0 top-0.5 relative">
						<icon-fas-circle-info class="fill-primary-blue-base" aria-hidden="true" />
					</div>
					<div class="typo-body-text-2">
						<span v-html-sanitize="props.flightScheduleNote"></span>
					</div>
				</div>
			</div>
		</div>
	</template>

</MobileSubpage>
</template>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.FlightSchedule {

}

</style>
