import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { waitForDocumentReadyState } from '~/logic/helpers/dom.js';
import { waitFor } from '~/logic/helpers/utils';
import { i18nGlobal } from '~/logic/i18n.js';
import { getDisplacementInKilometer } from '~/logic/helpers/gps.js';
import { forEachRight as _forEachRight, forEach as _forEach } from 'lodash';

const GROUP_ID = {
	NEAR_YOU: 'NEAR_YOU',
	POPULAR_DEST: 'POPULAR_DEST',
	CODESHARE: 'CODESHARE',
	EMPTY_LABEL: 'EMPTY_LABEL',
	WITH_MH: 'WITH_MH',
};
const GROUP_ID_TO_LABEL_MAP = {
	[GROUP_ID.NEAR_YOU]: i18nGlobal.t('Near You'),
	[GROUP_ID.POPULAR_DEST]: i18nGlobal.t('Popular Destinations'),
	[GROUP_ID.WITH_MH]: i18nGlobal.t('With Malaysia Airlines'),
	[GROUP_ID.CODESHARE]: i18nGlobal.t('With Codeshare and Partner Network'),
	[GROUP_ID.EMPTY_LABEL]: ' ',
};


let hasInitiated = false;
let hasInitCompleted = false;
let hasInitiated_LocaleTranslation = false;

const baseList = ref(null);
const destinationList = ref(null);

const baseListError = ref(null);
const isBaseListLoading = ref(true);

const currentSiteLocale = ref(null);
const siteName = window.siteName;

class ONDFetchService {
	constructor ({
		endpoint,
	} = {}) {
		this.endpoint = endpoint;
		this.type = 'base';
	}

	/** *********NEW METHOD******** */
	async fetchOriginList () {
		const apiResponse = await axios.get(this.endpoint, { params: { type: this.type } });
		if (String(apiResponse.status).startsWith('4') || String(apiResponse.status).startsWith('5')) {
			throw new Error(apiResponse.statusText);
		}
		if (String(apiResponse.status) === '200' && !apiResponse.data.status) {
			throw new Error(apiResponse.statusText);
		}
		return apiResponse.data.ondList;
	}

	async fetchDestinationList (fromValue) {
		const apiResponse = await axios.get(this.endpoint, { params: { type: this.type, origin: fromValue } });
		if (String(apiResponse.status).startsWith('4') || String(apiResponse.status).startsWith('5')) {
			throw new Error(apiResponse.statusText);
		}
		if (String(apiResponse.status) === '200' && !apiResponse.data.status) {
			throw new Error(apiResponse.statusText);
		}
		return apiResponse.data.ondList;
	}
}

let _fetchService = null;

const init = async () => {
	let currentTranslationLocale = currentSiteLocale.value;
	const waitPageProperties = waitFor(() => !!unref(useGlobalAEMState().pageProperties), 500);
	await waitPageProperties.start();

	_fetchService = new ONDFetchService({
		endpoint: '/bin/mh/revamp/mhexplorer/fetchOndRecords',
		...(currentTranslationLocale ? { localeParam: currentTranslationLocale } : null),
	});
	
	isBaseListLoading.value = true;
	
	try {
		const data = await _fetchService.fetchOriginList();
		baseList.value = data;
	} catch (err) {
		baseListError.value = err.message || err;
	}
	isBaseListLoading.value = false;
	
	hasInitCompleted = true;
};



const processDataListIntoGroups = (list, _groupingCriterias = []) => {
	const groupingCriterias = _groupingCriterias.filter(Boolean);
	
	let result = groupingCriterias.map(([groupId, groupPredicate]) => {
		return {
			label: GROUP_ID_TO_LABEL_MAP[groupId],
			options: [],
			groupId,
		};
	});
	
	list.forEach((item) => {
		_forEach(groupingCriterias, ([groupId, groupPredicate], criteriaIndex) => {
			let includeThisItem = false;
			if (typeof groupPredicate === 'string') {
				if (item[groupPredicate] === true || item[groupPredicate] === groupId) {
					includeThisItem = true;
				}
			} else if (typeof groupPredicate === 'function') {
				if (groupPredicate(item) === true) includeThisItem = true;
			} else if (typeof groupPredicate === 'boolean') {
				if (groupPredicate === true) includeThisItem = true;
			}
			if (includeThisItem) {
				result[criteriaIndex].options.push(item);
				return false; // exit this forEach early, so that this same item won't be evaluated for other groups
			}
			return true; // let the loop continue
		});
	});

	// remove empty group
	result = result.filter((group) => (group.options.length > 0));
	return result;
};

const sortNearYou_MUTATE = (list) => {
	const groupData = list.find((listItem) => listItem.groupId === GROUP_ID.NEAR_YOU);
	if (!groupData) return;
	groupData.options.sort((a, b) => {
		return a?.displacement - b?.displacement;
	});
};

const appendAirportInfo = (item) => {
	const dataObj = {
		_original: item,
		value: item.airportCode,
		displayValue: item.locale?.cityName ?? item.city,
		label: `${item.locale?.cityName ?? item.city}, ${item.locale?.countryName ?? item.countryName}`,
		airportCode: item.airportCode,
		code: item.airportCode,
		city: item.locale?.cityName ?? item.city,
		searchString: `${item.airportCode} | ${item.city}, ${item.countryName} | ${item.airportName} | ${item.locale?.cityName ?? ''}, ${item.locale?.countryName ?? ''} | ${item.locale?.airportName ?? ''}`,
		description: item.locale?.airportName ?? item.airportName,
		countryName: item.locale?.countryName ?? item.countryName,
		countryCode: item.countryCode,
		// latitude: parseFloat(item.lattitude), // TODO BE to fix this 
		latitude: parseFloat(item.latitude),
		longitude: parseFloat(item.longitude),
		...(item.domesticDestinations ? { domesticDestinations: item.domesticDestinations } : null),
		isMultiCity: item.isMultiCity,
		isRedemption: item.isRedemption,
		isMHExplorer: item.isMHExplorer,
		isPopularDestination: item.isPopularDestination,
		isMHSportsKBS: item.isMHSportsKBS,
		isMHSportsJDT: item.isMHSportsJDT,
		isCUG1: item.isCUG1,
		isCUG2: item.isCUG2,
	};
	return dataObj;
};

const processDatalist = (dataList, { listIdentifier, direction, excludeList, userCoords, currentPageCountry, normalRevenueONDList, redemptionRevenueONDList, multicityONDList } = {}) => {
	let result = unref(dataList);
	
	if (!Array.isArray(result)) {
		console.warn(`processDatalist() 'dataList' is not an Array. No-op.`);
		return result;
	}
	if (result.length === 0) return result;
	result = result.map(appendAirportInfo);
	
	const userPosition = userCoords ? {
		latitude: userCoords.latitude,
		longitude: userCoords.longitude,
	} : null;

	switch (unref(listIdentifier)) {
		case 'FLIGHT_SEARCH_CASH': {
			const listMapByAirportCode = new Map();
			
			result.forEach((item) => {
				const airportCode = item.airportCode;
				const isRevenue = item.isMHExplorer;
				
				if (!isRevenue) return;
				if (normalRevenueONDList && !item[normalRevenueONDList]) return;
				if (Array.isArray(excludeList) && excludeList.find((item) => item.airportCode === airportCode)) return;
				
				if (listMapByAirportCode.has(airportCode)) {
					// already exist in the mapping
					const retrieved = listMapByAirportCode.get(airportCode);
					
					if (item.isRedemption) {
						retrieved.isRedemption = true;
						// if (retrieved.code === 'LHR') debugger;
						if (retrieved.groupTo !== GROUP_ID.NEAR_YOU) retrieved.groupTo = GROUP_ID.WITH_MH;
					}
					if (item.isPopularDestination) {
						retrieved.isPopularDestination = true;
						if (retrieved.groupTo !== GROUP_ID.NEAR_YOU && direction === 'to') retrieved.groupTo = GROUP_ID.POPULAR_DEST;
					}
				} else {
					// new entry
					let groupTo = '';
					
					if (direction === 'to' && item.isPopularDestination) {
						groupTo = GROUP_ID.POPULAR_DEST;
					} else {
						groupTo = item.isRedemption ? GROUP_ID.WITH_MH : GROUP_ID.CODESHARE;
					}
					
					let displacement = -1;
					
					// Group by 'Near You'
					if (direction === 'from') {
						if (userPosition) {
							// geolocation available, group by GPS
							displacement = getDisplacementInKilometer(userPosition, {
								latitude: item.latitude,
								longitude: item.longitude,
							});
							
							if (displacement >= 0 && displacement <= 75) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						} else {
							// no geolocation, group by country
							if (item.countryCode?.toUpperCase() === currentPageCountry.toUpperCase()) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						}
					}
					
					listMapByAirportCode.set(airportCode, {
						...item,
						groupTo,
						displacement,
					});
				}
			});
			
			result = Array.from(listMapByAirportCode.values());

			result = processDataListIntoGroups(result, [
				direction === 'from' ? [GROUP_ID.NEAR_YOU, 'groupTo'] : null,
				direction === 'to' ? [GROUP_ID.POPULAR_DEST, 'groupTo'] : null,
				[GROUP_ID.WITH_MH, 'groupTo'],
				[GROUP_ID.CODESHARE, 'groupTo'],
				[GROUP_ID.EMPTY_LABEL, true],
			]);
			
			sortNearYou_MUTATE(result);
			break;
		}
		case 'FLIGHT_SEARCH_ENRICH': {
			const listMapByAirportCode = new Map();
			
			result.forEach((item) => {
				const airportCode = item.airportCode;
				const isRedemption = item.isRedemption;

				if (!isRedemption) return;
				if (redemptionRevenueONDList && !item[redemptionRevenueONDList]) return;
				if (Array.isArray(excludeList) && excludeList.includes(airportCode)) return;

				if (listMapByAirportCode.has(airportCode)) {
					// already exist in the mapping
					const retrieved = listMapByAirportCode.get(airportCode);

					if (item.isRedemption) {
						retrieved.isRedemption = true;
						if (retrieved.groupTo !== GROUP_ID.NEAR_YOU) retrieved.groupTo = GROUP_ID.WITH_MH;
					}
					if (item.isPopularDestination) {
						retrieved.isPopularDestination = true;
						if (retrieved.groupTo !== GROUP_ID.NEAR_YOU && direction === 'to') retrieved.groupTo = GROUP_ID.POPULAR_DEST;
					}
				} else {
					// new entry
					let groupTo = '';

					if (direction === 'to' && item.isPopularDestination) {
						groupTo = GROUP_ID.POPULAR_DEST;
					} else {
						groupTo = GROUP_ID.WITH_MH;
					}

					let displacement = -1;

					// Group by 'Near You'
					if (direction === 'from') {
						if (userPosition) {
							// geolocation available, group by GPS
							displacement = getDisplacementInKilometer(userPosition, {
								latitude: item.latitude,
								longitude: item.longitude,
							});

							if (displacement >= 0 && displacement <= 75) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						} else {
							// no geolocation, group by country
							if (item.countryCode?.toUpperCase() === currentPageCountry.toUpperCase()) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						}
					}

					listMapByAirportCode.set(airportCode, {
						...item,
						groupTo,
						displacement,
					});
				}
			});

			result = Array.from(listMapByAirportCode.values());

			result = processDataListIntoGroups(result, [
				direction === 'from' ? [GROUP_ID.NEAR_YOU, 'groupTo'] : null,
				direction === 'to' ? [GROUP_ID.POPULAR_DEST, 'groupTo'] : null,
				[GROUP_ID.WITH_MH, 'groupTo'],
				[GROUP_ID.EMPTY_LABEL, true],
			]);

			sortNearYou_MUTATE(result);
			break;
		}
		default: {
			break;
		}
	}
	
	return result;
};



export const useMHEONDList = ({ fromValueRef, toValueRef, listIdentifier, translationLocale, normalRevenueONDList, redemptionRevenueONDList, multicityONDList } = {}) => {
	const instance = getCurrentInstance();

	if (!hasInitiated) { hasInitiated = true; init(); }

	if (!hasInitiated_LocaleTranslation && translationLocale) {
		hasInitiated_LocaleTranslation = true;
		currentSiteLocale.value = translationLocale;
		init();
	}
	
	const internalIsLoadingFrom = ref(false);
	const internalIsLoadingTo = ref(false);
	
	const computedIsLoadingFrom = computed(() => {
		isBaseListLoading.value; // eslint-disable-line
		if (internalIsLoadingFrom.value) return true;
		if (isBaseListLoading.value) return true;
		return false;
	});
	const computedIsLoadingTo = computed(() => {
		isBaseListLoading.value; // eslint-disable-line
		if (internalIsLoadingTo.value) return true;
		if (isBaseListLoading.value) return true;
		return false;
	});
	
	const computedOptionsFrom = computed(() => {
		const { pageProperties } = useGlobalAEMState();
		const currentPageCountry = pageProperties.value.rootCountry;
		
		let result = [];
		
		reactiveUserGeolocation.value; // eslint-disable-line
		baseList.value; // eslint-disable-line
		
		let dataList = [];
		// determine the dataList
		if (baseList.value?.originList) {
			dataList = baseList.value.originList;
		}
		
		result = processDatalist(dataList, {
			isBST: false,
			listIdentifier: unref(listIdentifier),
			direction: 'from',
			excludeList: unref(toValueRef) ? [unref(toValueRef)] : null,
			userCoords: userCoords.value,
			currentPageCountry,
			normalRevenueONDList,
			redemptionRevenueONDList,
		});
		
		return result;
	});

	const computedOptionsTo = computed(() => {
		const { pageProperties } = useGlobalAEMState();
		const currentPageCountry = pageProperties.value.rootCountry;

		let result = [];
		let dataList = [];

		if (destinationList.value?.destinations) {
			dataList = destinationList.value.destinations;
		}
		result = processDatalist(dataList, {
			isBST: false,
			listIdentifier: unref(listIdentifier),
			direction: 'to',
			excludeList: unref(fromValueRef) ? [unref(fromValueRef)] : null,
			userCoords: false,
			currentPageCountry,
			normalRevenueONDList,
			redemptionRevenueONDList,
		});
		
		return result;
	});
	
	const computedOptionsFromFlattened = computed(() => {
		return computedOptionsFrom.value.reduce((acc, item) => {
			if (item.options) {
				acc.push(...item.options);
			} else {
				acc.push(item);
			}
			return acc;
		}, []);
	});
	const computedOptionsToFlattened = computed(() => {
		return computedOptionsTo.value.reduce((acc, item) => {
			if (item.options) {
				acc.push(...item.options);
			} else {
				acc.push(item);
			}
			return acc;
		}, []);
	});
	
	const updateFromValue = async () => {
		const waitForInitComplete = waitFor(() => hasInitCompleted === true);
		await waitForInitComplete.start();
		
		destinationList.value = null;
		internalIsLoadingTo.value = true;
		try {
			const responseData = await _fetchService.fetchDestinationList(fromValueRef.value?.code ?? fromValueRef.value);
			destinationList.value = responseData;
		} catch (err) {
			console.warn(`fetchToList API error`, err);
		} finally {
			internalIsLoadingTo.value = false;
		}
		
		if (typeof fromValueRef.value === 'string' && fromValueRef.value.length !== 3) return;

		// ---- If value ref is a string, convert it to Object by finding the corresponding item ----
		if (typeof fromValueRef.value === 'string') {
			const foundItem = computedOptionsFromFlattened.value.find((item) => item.code === fromValueRef.value);
			if (foundItem) {
				fromValueRef.value = foundItem;
			} else {
				console.warn(`useONDList.js: Invalid from(origin) string value of "${fromValueRef.value}"`);
			}
		}
		// ---- / END If value ref is a string, convert it to Object by finding the corresponding item ----
	};
	
	const updateToValue = async () => {
		const waitForInitComplete = waitFor(() => hasInitCompleted === true);
		await waitForInitComplete.start();
		
		// ---- If value ref is a string, convert it to Object by finding the corresponding item ----
		if (typeof toValueRef.value === 'string') {
			const foundItem = computedOptionsToFlattened.value.find((item) => item.code === toValueRef.value);
			console.log('toValueRef foundItem = ', foundItem);
			if (foundItem) {
				toValueRef.value = foundItem;
			} else {
				console.warn(`useONDList.js: Invalid to(destination) string value of "${toValueRef.value}"`);
			}
		}
		// ---- / END If value ref is a string, convert it to Object by finding the corresponding item ----
	};
	const reactiveUserGeolocation = ref(null);
	
	const setUserGeolocation = (userGeolocation) => {
		reactiveUserGeolocation.value = userGeolocation;
	};
	
	const userCoords = computed(() => {
		if (!reactiveUserGeolocation.value) return null;
		if (unref(reactiveUserGeolocation.value.error)) return null;
		return reactiveUserGeolocation.value.coords;
	});
	
	const swapLocations = async () => {
		if (internalIsLoadingFrom.value || internalIsLoadingTo.value) return false;
		
		if (!fromValueRef.value || !toValueRef.value) {
			return false;
		} else {
			[fromValueRef.value, toValueRef.value] = [toValueRef.value, fromValueRef.value];
			destinationList.value = null;
			// check new value of from for eligibility
			const foundItem = computedOptionsFromFlattened.value.find((item) => item.value === fromValueRef.value.value);
			fromValueRef.value = foundItem || null;
			internalIsLoadingTo.value = true;
			try {
				const responseData = await _fetchService.fetchDestinationList(fromValueRef.value?.code ?? fromValueRef.value);
				destinationList.value = responseData;
				const foundItem = computedOptionsToFlattened.value.find((item) => item.value === toValueRef.value.value);
				toValueRef.value = foundItem || null;
				
				// console.log('computedOptionsToFlattened.value = ', computedOptionsToFlattened.value);
				// console.log('foundItem = ', foundItem);
			} catch (err) {
				console.warn(`fetchToList API error`, err);
			} finally {
				internalIsLoadingTo.value = false;
			}
		}
			
	};

	return {
		optionsFrom: computedOptionsFrom,
		optionsTo: computedOptionsTo,

		optionsFromFlattened: computedOptionsFromFlattened,
		optionsToFlattened: computedOptionsToFlattened,
		
		isLoadingFrom: computedIsLoadingFrom,
		isLoadingTo: computedIsLoadingTo,
		
		updateFromValue,
		updateToValue,
		swapLocations,
		setUserGeolocation,

	};
};