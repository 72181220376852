<script>
export default {
	name: 'SubTabCities',
};
</script>

<script setup>
import ONDPicker from '~/components/BookingWidget/ONDPicker.vue';
import DatePicker from '~/components/form/DatePicker.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import CTAButton from '~/aem-components/CTAButton.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import Tooltip from '~/components/Tooltip.vue';
import dayjs from 'dayjs';

const { pageProperties } = useGlobalAEMState();


const props = defineProps({
	externalUrl: { type: String, default: '' },
	buttonLabel: { type: String, default: '' },
	dateField: { type: Object, default: () => null },
	description: { type: String, default: '' },
	from: { type: Object, default: () => null },
	label: { type: String, default: '' },
	note: { type: String, default: '' },
	to: { type: Object, default: () => null },
	tooltipBody: { type: String, default: '' },
	tooltipLabel: { type: String, default: '' },

	componentIdAA: { type: String, default: '' },
});
const emit = defineEmits([
	'update:modelValue',
	'form-submission',
]);

const formWrapper = ref(null);

const handleSubmitValid = (values, actions) => {
	console.log('✅ handleSubmitValid values = ', values);
	emit('form-submission', values);
	triggerRedirection();
};
const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
};

const datepickerValue = ref(dayjs().format('YYYY-MM-DD'));

const ondValueFrom = ref(null);
const ondValueTo = ref(null);

const isBtnSubmitDisabled = computed(() => {
	datepickerValue.value; // eslint-disable-line
	ondValueFrom.value; // eslint-disable-line
	ondValueTo.value; // eslint-disable-line
	if (!formWrapper.value) return true;
	return !datepickerValue.value || !ondValueFrom.value || !ondValueTo.value || Object.keys(formWrapper.value.errors).length !== 0;
});

const triggerRedirection = () => {
	const params = {
		language: pageProperties.value.rootLanguage,
		departureDate: datepickerValue.value,
		departureCity: ondValueFrom.value.value,
		arrivalCity: ondValueTo.value.value,
	};

	const url = new URL(props.externalUrl);

	Object.keys(params).forEach((key) => {
		url.searchParams.append(key, params[key]);
	});
	
	window.location.href = url.toString();
};
</script>


<template>
<div class="SubTabCities">
	<FormWrapper
		ref="formWrapper"
		v-slot="{ errors }"
		@submit-valid="handleSubmitValid"
		@submit-invalid="handleSubmitInvalid"
	>
		<p v-if="props.description" v-html-sanitize="props.description" class="mb-5"></p>

		<Tooltip v-if="props.tooltipLabel" class="inline-flex mb-5">
			<template #default>
				<div class="flex items-center">
					<icon-fas-circle-question class="fill-primary-blue-base mr-4 rtl:(mr-0 ml-4)" aria-hidden="true" />
					{{ props.tooltipLabel }}
				</div>
			</template>
			<template #mobile-title>
				{{ props.tooltipLabel }}
			</template>
			<template #tooltip-content>
				<p v-if="props.tooltipBody" v-html-sanitize="props.tooltipBody"></p>
			</template>
		</Tooltip>
		
		<div class="flex gap-6 xl:gap-3 xl:flex-wrap lg:flex-col mb-5">
			<div class="w-1/4 flex-none xl:w-1/3 lg:w-full">
				<DatePicker
					v-model="datepickerValue"
					name="flight-status-cities-date"
					:ariaLabel="props.dateField.label"
					:maxDate="`t+${props.dateField.maxDaysFuture}`"
					:minDate="`t-${props.dateField.maxDaysPast}`"
					variant="booking-widget"
					required
				>
				</DatePicker>
			</div>
			<div class="flex-grow lg:w-full">
				<ONDPicker
					v-model:modelValueFrom="ondValueFrom"
					v-model:modelValueTo="ondValueTo"
					nameFrom="flight-status-cities-from"
					nameTo="flight-status-cities-to"
					:requiredFrom="props.from.isMandatory"
					:requiredTo="props.to.isMandatory"
					:requiredErrorMsgFrom="props.from.errorMessage"
					:requiredErrorMsgTo="props.to.errorMessage"
					:labelTextFrom="props.from.label"
					:labelTextTo="props.to.label"
					ondListIdentifier="FLIGHT_STATUS"
				>
				</ONDPicker>
			</div>
			<div class="w-[max-content] flex-none xl:w-full">
				<CTAButton
					v-aa="[
						{
							clickName: props.buttonLabel,
							clickComponentType: 'Button',
							componentName: 'SubTabCities',
							componentID: props.componentIdAA,
						},
						{
							name: props.buttonLabel,
							type: 'exit',
						},
					]"
					:isSubmit="true"
					:sizeType="isMobileViewport ? 'small' : 'large'"
					:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
					:disabled="isBtnSubmitDisabled"
					alignmentDesktop="right"
					class="lg:mx-auto lg:mt-3"
					:ctaText="props.buttonLabel"
				>
				</CTAButton>
			</div>
		</div>

		<div
			v-if="props.note"
			class="bg-primary-blue-extralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl"
		>
			<icon-fas-circle-info class="fill-primary-blue-base text-base" />
			<div class="text-sm">
				<p v-html-sanitize="props.note"></p>
			</div>
		</div>
	</FormWrapper>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.SubTabCities {
	
}

</style>
