<script>
export default {
	name: 'BookFlight',
	inheritAttrs: false,
};
</script>


<script setup>
import ToggleSwitch from '~/components/form/ToggleSwitch.vue';
import Tooltip from '~/components/Tooltip.vue';
import Dropdown from '~/components/form/Dropdown.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import ONDPicker from '~/components/BookingWidget/ONDPicker.vue';
import DateRangePicker from '~/components/BookingWidget/DateRangePicker.vue';
import TextField from '~/components/form/TextField.vue';
import PassengerAndCabinClass from '~/components/BookingWidget/PassengerAndCabinClass.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import AppHyperlink from '~/components/AppHyperlink.vue';
import yup from 'mh-yup';
import dayjs from 'dayjs';
import { isMobileViewport, isXXXL } from '~/logic/composables/breakpoints.js';
import TransitionHeight from '~/components/transition/TransitionHeight.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { useRouteHash } from '~/logic/composables/useRouteHash.js';
import { useEventBus } from '@vueuse/core';
import { useBSTOverlayInterimData } from '~composables/booking-widget/useBSTOverlayInterimData.js';
import { i18nGlobal } from '~/logic/i18n.js';
import { useIsUkVariant, useSyncPassengerDetails } from '~/logic/composables/booking-widget/usePassengers.js';
import { useAuthStore } from '~/logic/auth/auth-store.js';
import { getCookie } from '~/logic/helpers/cookies.js';
import { useLowFarePriceList } from '~composables/booking-widget/useLowFarePriceList.js';
import { isUrlMatchCurrentHostname, formatNumberWithAbbreviation } from '~/logic/helpers/string.js';
import { waitFor } from '~/logic/helpers/utils.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';
import { pushAA_searchError } from '~/logic/adobe-analytic.js';
import { useIsRTL } from '~/logic/helpers/is-rtl.js';

const props = defineProps({
	hideTodayDateIndicator: { type: Boolean, default: false },
	subHeaderTabDescription: { type: String, default: '' },
	subHeaderTabTooltipBody: { type: String, default: '' },
	subHeaderTabTooltipLabel: { type: String, default: '' },
	note: { type: String, default: '' },
	searchFlightCTAText: { type: String, default: i18nGlobal.t('Search flight') },

	ondSelection: { type: Object, default: null },
	datePicker: { type: Object, default: null },
	passengerSelection: { type: Object, default: null },
	promoCodeLabel: { type: String, default: null },
	promoCodeErrorMessage: { type: String, default: i18nGlobal.t('Conditions not met for promo code to be applied') },

	bstNoteTop: { type: String, default: '' },
	bstNoteCTAText: { type: String, default: '' },
	bstNoteCTAURL: { type: String, default: '' },

	subHeaderTooltipCTATextMobileLabel: { type: String, default: '' },
	fromQuestionMobile: { type: String, default: '' },
	toQuestionMobile: { type: String, default: '' },
	datePickerQuestionMobile: { type: String, default: '' },
	passengerSelectionQuestion: { type: String, default: '' },
	flightSearchApiUrl: { type: String, default: '' },
	cabinClassCashList: { type: Array, default: () => [] },
	cabinClassMilesList: { type: Array, default: () => [] },

	togglePointsApiURL: { type: String, default: '' },

	enableMattaToggleButton: { type: Boolean, default: false },
	mattaToggleButtonLabel: { type: String, default: '' },

	hideEnrichToggle: { type: Boolean, default: false },
	hidePromoCodeField: { type: Boolean, default: false },
	
	enrichAlwaysOn: { type: Boolean, default: false },

	enrichAlwaysOnCountriesData: { type: Array, default: () => [] },

	loggedInMessageNote: { type: String, default: '' },
	nonLoggedInMessageNote: { type: String, default: '' },

	currencyLabel: { type: String, default: '' },
	defaultCurrency: { type: String, default: '' },

	componentIdAA: { type: String, default: '' },
});

const emit = defineEmits([
	'save-search-data',
	'form-submission',
]);

const { isRTL } = useIsRTL();

const formWrapper = ref(null);
const rootEl = ref(null);
const passengerAndCabinClassEl = ref(null);
const dateRangePickerEl = ref(null);
const ondPickerEl = ref(null);

const dateRangeValueFrom = ref(null);
const dateRangeValueTo = ref(null);

const ondValueFrom = ref(null);
const ondValueTo = ref(null);

const promoCodeValue = ref(null);
const currencyValue = ref(props.defaultCurrency);
const payByEnrichPoints = ref(false);
const token = ref('');

const { pageProperties, isEditorMode } = useGlobalAEMState();

const isExpanded = ref(true);

const enrichAlwaysOnEligibility = ref(false);
const optEnrichAlwaysOnOffer = ref(false);

const country = pageProperties.value.rootCountry;
const language = pageProperties.value.rootLanguage;

const { isSapphireCookiePresent } = useAuthStore();

const capitalizeString = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const showAlwaysOnEnrichNonLoginMsg = computed(() => {
	return props.enrichAlwaysOn && !isSapphireCookiePresent() && props.nonLoggedInMessageNote;
});


const lowFareApiUrl = pageProperties?.value?.lowFareApiUrl;
const isOneWay = ref(false);


const handleWatchIsOneWay = (flag) => {
	isOneWay.value = flag;
};


const {
	isLoggedIn,
	isAuthStateReady,
} = useAuthStore();

const { hash, hashQuery } = useRouteHash();
const SAPPHIRE_COOKIE = 'sapphire';
token.value = getCookie(SAPPHIRE_COOKIE) ?? null;


const promoCodeTextField = ref(null);

const handleSubmitValid = async (values, actions) => {
	console.log('✅ handleSubmitValid values = ', values);

	const searchData = {
		payByEnrichPoints: payByEnrichPoints.value,
		dateRangeValueFrom: dateRangeValueFrom.value,
		dateRangeValueTo: dateRangeValueTo.value,
		isOneWay: !dateRangeValueTo.value,
		ondValueFrom: ondValueFrom.value,
		ondValueTo: ondValueTo.value,
		promoCodeValue: promoCodeValue.value || '',
		currencyValue: currencyValue.value,

		supportedCurrencies: ondValueFrom.value.supportedCurrencies,
		defaultCurrency: ondValueFrom.value.defaultCurrency,
		
		adultsCount: passengerAndCabinClassEl.value.adults.count.value,
		infantsCount: passengerAndCabinClassEl.value.infants.count.value,
	};
	
	emit('save-search-data', searchData);
	emit('form-submission', searchData);

	try {

		const mobileDataParams = {
			'type': searchData.isOneWay ? '2' : '1',
			'departure_station': searchData.ondValueFrom.code,
			'arrival_station': searchData.ondValueTo.code,
			'departure_date': dayjs(searchData.dateRangeValueFrom).format('DD/MM/YYYY'),
			'return_date': (!searchData.isOneWay && searchData.dateRangeValueTo ? dayjs(searchData.dateRangeValueTo).format('DD/MM/YYYY') : ''),
			'adult': searchData.adultsCount.toString(),
			'infant': searchData.infantsCount.toString(),
			'organization_code': 'TEST',
			'promotion_code': searchData.promoCodeValue,
			'currency_code': searchData.currencyValue || 'MYR',
			'Source': 'Corporate',
		};

		const desktopDataParams = {
			'RadioButtonMarketStructure': searchData.isOneWay ? 'OneWay' : 'RoundTrip',
			'TextBoxMarketOrigin1': searchData.ondValueFrom.code,
			'TextBoxMarketDestination1': searchData.ondValueTo.code,
			'DropDownListMarketMonth1': dayjs(searchData.dateRangeValueFrom).format('YYYY-MM'),
			'DropDownListMarketDay1': dayjs(searchData.dateRangeValueFrom).format('DD'),
			'DropDownListMarketMonth2': !searchData.isOneWay && searchData.dateRangeValueTo ? dayjs(searchData.dateRangeValueTo).format('YYYY-MM') : '',
			'DropDownListMarketDay2': !searchData.isOneWay && searchData.dateRangeValueTo ? dayjs(searchData.dateRangeValueTo).format('DD') : '',
			'DropDownListPassengerType_ADT': searchData.adultsCount.toString(),
			'DropDownListPassengerType_INFANT': searchData.infantsCount.toString(),
			'DropDownListCurrency': searchData.currencyValue || 'MYR',
			'TextBoxPromotionCode': searchData.promoCodeValue,
			'OrganizationCode': '',
			'Source': 'Corporate',
		};
		
		const entriesMassageData = Object.entries(isMobileViewport.value ? mobileDataParams : desktopDataParams);

		// create hidden form and submit
		const method = 'post';
		const form = document.createElement('form');
		form.setAttribute('method', method);
		form.setAttribute('action', props.flightSearchApiUrl);
		
		for (let [index, [key, value]] of entriesMassageData.entries()) {
			// console.log(`apiResponse => ${index}: ${key}: ${value}`);
			const _key = parseInt(key);
			let hiddenField = document.createElement('input');
			hiddenField.setAttribute('type', 'hidden');
			hiddenField.setAttribute('name', key);
			hiddenField.setAttribute('value', value);
			form.appendChild(hiddenField);
		}

		document.body.appendChild(form);
		form.submit();

	} catch (err) {
		console.error('handleSubmitValid, ', 'Unable to fetch api', JSON.stringify(err));
		// from BE if status code === 400, it means promo code issue
		const { statusCode, message } = err.response.data;
		if (statusCode === 400) {
			// promo code error
			promoCodeTextField.value.setErrors(i18nGlobal.t(message));
			triggerAA_searchError({
				errorMsg: i18nGlobal.t(message),
			});
		}
	}
};
const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
};





const handleFormFocusWithin = () => {
	if (isSticked.value) return; // do not mess with this when in sticky mode
	isExpanded.value = true;
};
const handleFormBlurWithin = () => {
	//
};

const isBtnSubmitDisabled = computed(() => {
	const form = formWrapper.value;
	if (!form) return true;

	const isOneWay = dateRangePickerEl.value?.isOneWay;
	return (
		!form.values.locationFrom ||
		!form.values.locationTo ||
		!form.values.dateDeparture ||
		(!isOneWay && !form.values.dateReturn) ||
		Object.keys(form.errors).length !== 0
	);
});


const bookingWidgetBus = useEventBus('booking-widget');
const isSticked = ref(false);

bookingWidgetBus.on((event, payload) => {
	switch (event) {
		case 'sticky:sticked': {
			isSticked.value = true;
			break;
		}
		case 'sticky:unsticked': {
			isSticked.value = false;
			break;
		}
		default: {
			// console.log(`Unknown event: "${event}". Ignoring it.`);
			break;
		}
	}
});

const handleExpandTransitionEnd = () => {
	// no need to call this anymore
	// bookingWidgetBus.emit('refresh-scroll-trigger');
};
/* const handleBonusSideTripBannerChanged = () => {
	bookingWidgetBus.emit('refresh-scroll-trigger');
}; */


const areDatesValid = (from, to = null) => {
	const today = dayjs();
	const dayJsMaxAllowedDaysAhead = today.add(datePickerMaxAllowedFutureDays.value, 'day');
	const dayJsFrom = dayjs(from);
	const dayJsTo = dayjs(to);
	
	if (dayJsFrom.isValid() === false || (to && dayJsTo.isValid() === false)) return false;
	
	if (dayJsFrom.isBefore(today, 'day')) {
		return false;
	}
	if (to && dayJsTo.isAfter(dayJsMaxAllowedDaysAhead, 'day')) {
		return false;
	}
	return true;
};

const validateAndRestoreDates = ({ from, to = null, isOneWay = false } = {}) => {
	if (areDatesValid(from, (isOneWay ? null : to))) {
		// valid date
		dateRangeValueFrom.value = dayjs(from).format('YYYY-MM-DD');
		dateRangeValueTo.value = (to && dayjs(to).format('YYYY-MM-DD')) ?? null;
	} else {
		// invalid date
		dateRangeValueFrom.value = null;
		dateRangeValueTo.value = null;
		
		// have to reset the validation here, so that the 'setTouched()' and 'setErrors()' inside the nextTick() will work
		dateRangePickerEl.value?.inputElFrom?.resetField();
		
		nextTick().then(() => {
			try {
				dateRangePickerEl.value?.inputElFrom?.setTouched(true);
				dateRangePickerEl.value?.inputElFrom?.setErrors(i18nGlobal.t('The selected dates are no longer valid. Please reselect.'));
			} catch {
				// do nothing
			}
		});
	}
};

const restorePastSearch = (searchData) => {
	ondValueFrom.value = searchData.ondValueFrom;
	ondValueTo.value = searchData.ondValueTo;

	if ('defaultCurrency' in searchData) {
		nextTick(() => {
			currencyValue.value = searchData.defaultCurrency;
		});
	}
	
	validateAndRestoreDates({
		from: searchData.dateRangeValueFrom,
		to: searchData.dateRangeValueTo || null,
		isOneWay: !!searchData.isOneWay,
	});
	
	promoCodeValue.value = searchData.promoCodeValue;
	
	passengerAndCabinClassEl.value?.setValues({
		adults: searchData.adultsCount,
		infants: searchData.infantsCount,
	});
	
	// set one-way
	dateRangePickerEl.value.handleSetIsOneWay(!!searchData.isOneWay);
};

const { commitDataToBSTOverlay, registerNotifier } = useBSTOverlayInterimData();

registerNotifier('book-flight', async (newData) => {
	ondValueFrom.value = newData.ondValueFrom;
	ondValueTo.value = newData.ondValueTo;
	
	dateRangeValueFrom.value = newData.dateRangeValueFrom;
	dateRangeValueTo.value = newData.dateRangeValueTo;
	
	// const wait = waitFor(() => !!passengerAndCabinClassEl.value, 400, 30); // <-- 400ms * 30 = 12 seconds
	// await wait.start();
	
	passengerAndCabinClassEl.value?.setValues({
		adults: newData.adultsCount,
		teens: newData.teensCount,
		children: newData.childrenCount,
		infants: newData.infantsCount,
		cabinClass: newData.cabinClassValue,
	});
	
	dateRangePickerEl.value.handleSetIsOneWay(!!newData.isOneWay);
});


const defaultMinDate = 0;
const defaultMaxDate = 361;
const datePickerMinAllowedFutureDays = computed(() => {
	const datePickerData = props.datePicker;
	if (!datePickerData?.departureDateConfig || typeof datePickerData?.departureDateConfig !== 'number') return defaultMinDate;
	return datePickerData?.departureDateConfig;
});

const datePickerMaxAllowedFutureDays = computed(() => {
	const datePickerData = props.datePicker;
	if (!datePickerData?.returnDateConfig || typeof datePickerData?.returnDateConfig !== 'number') return defaultMaxDate;
	return datePickerData?.returnDateConfig;
});


const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'flight-search-tab',
	subTabName: 'book-flight',
});

registerQueryChange(async (query) => {
	if (!query) return;
	
	// ondValueFrom.value = query.locationFrom ? await getONDValueByAirportCode(query.locationFrom, { direction: 'from' }) : null;
	// ondValueTo.value = query.locationTo ? await getONDValueByAirportCode(query.locationTo, { direction: 'to' }) : null;
	
	ondValueFrom.value = query.locationFrom;
	ondValueTo.value = query.locationTo;
	
	nextTick().then(async () => {
		await ondPickerEl.value?.updateFromValue();
		ondPickerEl.value?.updateToValue();
	});
	
	validateAndRestoreDates({
		from: query.dateDeparture,
		to: query.dateReturn || null,
		isOneWay: (query.isOneWay === 'true'),
	});
	
	promoCodeValue.value = query.promoCode ?? null;
	
	await nextTick();

	passengerAndCabinClassEl.value?.setValues({
		...(query.adultsCount ? { adults: parseInt(query.adultsCount) } : null),
		...(query.infantsCount ? { infants: parseInt(query.infantsCount) } : null),
	});

	if (query.isOneWay === 'true') {
		dateRangePickerEl.value.handleSetIsOneWay(!!query.isOneWay);
	}
});

const updateLocationFromTo = async (payload) => {
	const { locationFrom, locationTo } = payload;
	// if (locationFrom) ondValueFrom.value = await getONDValueByAirportCode(locationFrom, { direction: 'from' });
	// if (locationTo) ondValueTo.value = await getONDValueByAirportCode(locationTo, { direction: 'to' });

	if (locationFrom) ondValueFrom.value = locationFrom;
	if (locationTo) ondValueTo.value = locationTo;

	nextTick().then(async () => {
		await ondPickerEl.value?.updateFromValue();
		ondPickerEl.value?.updateToValue();
	});
};

const isUKVariant = ref(false);


watch([ondValueFrom, ondValueTo], async (newValue) => {
	// reset teen passenger count, once user select non-uk airport
	isUKVariant.value = useIsUkVariant({ origin: ondValueFrom.value, destination: ondValueTo.value });
	if (isUKVariant.value) return;
	passengerAndCabinClassEl.value?.setValues({
		teens: 0,
	});
});

const triggerAA_searchError = ({ errorMsg }) => {
	const errorInfo = {
		errorName: errorMsg,
	};
	const webInteractions = {
		name: props.promoCodeLabel,
		type: 'other',
	};
	pushAA_searchError([errorInfo, webInteractions]);
};

useSyncPassengerDetails(passengerAndCabinClassEl);

defineExpose({
	restorePastSearch,
	updateLocationFromTo,
	isExpanded,
});

const focusToDatePicker = (newValue) => {
	if (newValue && !isMobileViewport.value) dateRangePickerEl.value?.inputElFrom?.focus();
};

const currencyDropdownList = computed(() => {
	const defaultCurrencyArray = [{
		label: props.defaultCurrency,
		value: props.defaultCurrency,
	}];

	if (ondValueFrom.value !== null) {
		const currencyList = ondValueFrom.value.supportedCurrencies
			.split(', ')
			.map((currency) => {
				return {
					label: currency,
					value: currency,
				};
			});

		return currencyList;
	}

	// Default current on first load(no OND value from selected yet)
	return defaultCurrencyArray;
});

const promoFieldValidationFunc = (value) => {
	let yupSchema = yup.string().nullable().max(8, props.promoCodeErrorMessage);
	
	try {
		yupSchema.validateSync(value);
		return true;
	} catch (err) {
		return err.errors[0];
	}
};

const groupLabelsOverwrite = computed(() => {
	if (!props.ondSelection) return null;

	return {
		'NEAR_YOU': props.ondSelection?.nearYouLabel,
		'POPULAR_DEST': props.ondSelection?.popularDestinationsLabel,
		'WITH_MH': props.ondSelection?.withFireflyLabel,
		'CODESHARE': props.ondSelection?.withCodeShareAndPartnerNetworkLabel,
		'EMPTY_LABEL': ' ',
	};
});

const setDropdownCurrencyDefautOption = (ondItem) => {
	nextTick(() => {
		if (ondItem?.defaultCurrency) {
			currencyValue.value = ondItem.defaultCurrency;
		} else {
			currencyValue.value = props.defaultCurrency;
		}
	});
};


const formWrapperPromoCodeError = computed(() => {
	if (formWrapper.value !== null) {
		const form = formWrapper.value;

		return form.errors['promoCode'];
	}

	return null;
});


watch(formWrapperPromoCodeError, (newValueErrorText) => {
	if (newValueErrorText) {
		triggerAA_searchError({
			errorMsg: newValueErrorText,
		});
	}
});

</script>


<template>
<div
	ref="rootEl"
	class="BookFlight"
>
	<FormWrapper
		ref="formWrapper"
		v-slot="{ errors }"
		v-focus-within
		class="lg:mt-0"
		tabindex="-1"
		@submit-valid="handleSubmitValid"
		@submit-invalid="handleSubmitInvalid"
		@focus-within="handleFormFocusWithin"
		@blur-within="handleFormBlurWithin"
	>
		<div>
			<div v-if="props.subHeaderTabDescription" v-html-sanitize="props.subHeaderTabDescription" class="mb-5"></div>

			<Tooltip v-if="props.subHeaderTabTooltipLabel" class="inline-flex mb-5">
				<template #default>
					<div class="flex items-center">
						<icon-fas-circle-info class="fill-secondary-firefly-orange-extradark mr-4 rtl:(mr-0 ml-4)" aria-hidden="true" />
						{{ props.subHeaderTabTooltipLabel }}
					</div>
				</template>
				<template #mobile-title>
					{{ props.subHeaderTabTooltipLabel }}
				</template>
				<template #tooltip-content>
					{{ props.subHeaderTabTooltipBody }}
				</template>
				<template #mobile-cta-label>
					{{ props.subHeaderTooltipCTATextMobileLabel }}
				</template>
			</Tooltip>

			<div class="flex gap-6 lg:flex-col">
				<div class="w-6/10 lg:w-full">
					<ONDPicker
						ref="ondPickerEl"
						v-model:modelValueFrom="ondValueFrom"
						v-model:modelValueTo="ondValueTo"
						nameFrom="locationFrom"
						nameTo="locationTo"
						:ondListIdentifier="payByEnrichPoints ? 'FLIGHT_SEARCH_ENRICH' : 'FLIGHT_SEARCH_CASH'"
						:requiredFrom="true"
						:requiredTo="true"
						:requiredErrorMsgFrom="props.ondSelection?.fromSearchErrorMessage"
						:requiredErrorMsgTo="props.ondSelection?.toSearchErrorMessage"
						:labelTextFrom="props.ondSelection?.fromLabel"
						:labelTextTo="props.ondSelection?.toLabel"
						:noResultsText="props.ondSelection?.noResultMessage"
						:groupLabelsOverwrite="groupLabelsOverwrite"
						@update:modelValueFrom="setDropdownCurrencyDefautOption"
						@update:modelValueTo="focusToDatePicker"
					>
						<template #from-picker-mobile-title>
							<span>{{ props.fromQuestionMobile }}</span>
						</template>
						<template #to-picker-mobile-title>
							<span>{{ props.toQuestionMobile }}</span>
						</template>
					</ONDPicker>
				</div>
				<div class="w-4/10 lg:w-full">
					<DateRangePicker
						ref="dateRangePickerEl"
						v-model:modelValueFrom="dateRangeValueFrom"
						v-model:modelValueTo="dateRangeValueTo"
						:textFieldAttrsFrom="{
							ariaLabel: props.datePicker?.departureDateLabel ?? $t('Depart'),
							placeholder: $t('Select a date'),
						}"
						:textFieldAttrsTo="{
							ariaLabel: props.datePicker?.returnDateLabel ?? $t('Return'),
							placeholder: $t('Select a date'),
						}"
						:minDate="`t+${datePickerMinAllowedFutureDays}d`"
						:maxDate="`t+${datePickerMaxAllowedFutureDays}d`"
						minDateFromAfterSelectingTo="t"
						:disablePastDates="true"
						nameFrom="dateDeparture"
						nameTo="dateReturn"
						:requiredFrom="true"
						:requiredTo="true"
						:requiredErrorMsgFrom="props.datePicker?.invalidDateErrorMessage"
						:requiredErrorMsgTo="props.datePicker?.invalidDateErrorMessage"
						:labelReset="props.datePicker?.resetButtonCTAText ?? $t('Reset')"
						:labelDone="props.datePicker?.doneCTAText ?? $t('Done')"
						:labelOneWay="props.datePicker?.oneWayCTAText ?? $t('One-way')"
						:isHideTodayIndicator="props.hideTodayDateIndicator"
						@update:is-one-way="handleWatchIsOneWay"
					>
						<template #mobile-title>
							{{ props.datePickerQuestionMobile }}
						</template>
					</DateRangePicker>
				</div>
			</div>
		</div>

		<TransitionHeight
			:height="((isExpanded || isEditorMode || isMobileViewport) ? 'auto' : 0)"
			:class="((isExpanded || isEditorMode || isMobileViewport) ? 'overflow-visible' : '')"
			:transitionDuration="0.175"
			@transition-end="handleExpandTransitionEnd"
		>
			<div class="flex mt-4 lg:mt-6 gap-6 lg:flex-col">
				<div class="flex flex-grow gap-6 lg:flex-col w-6/10 lg:w-full">
					<div class="w-6/10 lg:w-full">
						<PassengerAndCabinClass
							ref="passengerAndCabinClassEl"
							:labelText="props.passengerSelection?.passengerAndCabinClassLabel"
							:passengerLabel="props.passengerSelection?.passengerLabel"
							:adultLabel="props.passengerSelection?.adultLabel"
							:infantLabel="props.passengerSelection?.infantLabel"
							:isShowEconomyClass="false"
							:isShowBusinessClass="false"
							:isShowBusinessSuiteClass="false"
							:isUKVariant="useIsUkVariant({ origin: ondValueFrom, destination: ondValueTo })"
							:maxInfantsCount="4"
							:isShowChildren="false"
						>
							<template #adult-description-label>{{ props.passengerSelection?.adultDescriptionLabel }}</template>
							<template #infant-description-label>{{ props.passengerSelection?.infantDescriptionLabel }}</template>

							<template #after-passengers>
								<AppHyperlink
									class="font-semibold after-passenger-link"
									:href="props.passengerSelection?.groupBookingPageURL"
									:showExternalLinkIcon="props.passengerSelection?.newTabAndExternalIcon"
									:target="props.passengerSelection?.newTabAndExternalIcon ? '_blank' : null"
								>
									{{ props.passengerSelection?.moreThan9PassengerDescription || $t('More than 9 passengers?') }}
								</AppHyperlink>
							</template>

							<template #mobile-title>
								{{ props.passengerSelectionQuestion }}
							</template>
						</PassengerAndCabinClass>
					</div>
					<div v-if="!payByEnrichPoints && !props.hidePromoCodeField" class="w-4/10 lg:w-full">
						<TextField
							ref="promoCodeTextField"
							v-model="promoCodeValue"
							name="promoCode"
							variant="booking-widget"
							:ariaLabel="props.promoCodeLabel"
							placeholder="E.g. 8990"
							:allowedKeys="/[a-zA-Z0-9]/"
							:validation="promoFieldValidationFunc"
						>
						</TextField>
					</div>
				</div>
				<div class="w-4/10 lg:w-full flex lg:flex-col gap-6 ml-auto lg:ml-0">
					<div class="w-6/10 lg:w-full">
						<Dropdown
							v-model="currencyValue"
							name="currency"
							desktopVariant="booking-widget"
							mobileVariant="booking-widget"
							:options="currencyDropdownList"
							:disabled="!ondValueFrom"
							labelText="Currency"
							aria-label="Currency"
						/>
					</div>
					<div class="w-max-4/10 lg:w-full >lg:flex">
						<CTAButton

							v-aa="[
								{
									clickName: props.searchFlightCTAText,
									clickComponentType: 'Button',
									componentName: 'BookFlight',
									componentID: props.componentIdAA,
								},
								{
									name: props.searchFlightCTAText,
									type: 'exit',
								},
							]"
							:sizeType="isMobileViewport ? 'small' : 'medium'"
							:isSubmit="true"
							ctaSizing="fluid"
							:disabled="isBtnSubmitDisabled"
							class="mx-0 !py-5 !px-6"
							containerClass="w-full"
						>
							{{ props.searchFlightCTAText }}
						</CTAButton>
					</div>
				</div>
			</div>

			<div
				v-if="props.note"
				class="bg-secondary-firefly-orange-ultralight text-primary-firefly-black-base flex gap-3 justify-start px-4 py-4 rounded-xl mt-6"
			>
				<icon-fas-circle-info class="fill-secondary-firefly-orange-extradark text-base" />
				<div
					v-html-sanitize="props.note"
					class="text-sm"
				>
				</div>
			</div>

			<div
				v-if="showAlwaysOnEnrichNonLoginMsg"
				class="bg-primary-blue-extralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl mt-6"
			>
				<icon-fas-circle-info class="fill-primary-blue-base text-base" />
				<div class="text-sm">
					<p> {{ props.nonLoggedInMessageNote }} </p>
				</div>
			</div>

			<div
				v-if="enrichAlwaysOnEligibility && props.loggedInMessageNote"
				class="flex mt-6"
			>
				<div class="rounded-xl bg-secondary-teal-extralight flex-grow px-5 py-4">
					<ToggleSwitch
						v-model="optEnrichAlwaysOnOffer"
						:ariaLabel="props.loggedInMessageNote"
						name="optEnrichAlwaysOnOffer"
					>
						<div v-html-sanitize="props.loggedInMessageNote"></div>
					</ToggleSwitch>
				</div>
			</div>
		</TransitionHeight>

	</FormWrapper>

</div>
</template>


<style lang="scss" scoped>
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.after-passenger-link {
	--a-tag-color: var(--primary-firefly-orange-base) !important;
	--a-tag-color-hover: var(--secondary-firefly-orange-medium) !important;
}

</style>