import dayjs from 'dayjs';
import { isEmpty as _isEmpty } from 'lodash';

const cacheData = new Map();

export const fetchLowFareAPI = async (origin, destination, departureDate, isPayPointRef, endpointUrl, isOneWay) => {
	const cacheKey = `${origin}|${destination}${departureDate ? `|${dayjs(departureDate).format('DDMMYY')}` : '' }${ isOneWay ? '|OneWay' : '|Return'}`;
	
	if (cacheData.get(cacheKey)) {
		return cacheData.get(cacheKey);
	}
	
	try {
		const responseData = await axios.get(endpointUrl, {
			params: {
				origin,
				destination,
				...(departureDate && isOneWay ? { firstdate: dayjs().format('DDMMYY') } : null),
				...(departureDate && !isOneWay ? { departdate: dayjs(departureDate).format('DDMMYY') } : null),
				...(departureDate && !isOneWay ? { fromDepartDate: true } : null),
				paymentType: isPayPointRef ? 'Miles' : 'Cash',
				// tripinterval: 100,
			},
		});

		if (responseData.status === 200) {
			cacheData.set(cacheKey, responseData.data);
			return responseData.data;
		}

		throw new Error(responseData);

	} catch (err) {
		console.warn(`useLowFarePrice.js fetchLowFareAPI API error`, err);
		throw new Error(err);
	}
	
};

export function useLowFarePriceList ({ originRef, destinationRef, departureDateRef, paymentTypeRef, endpointUrl, isOneWayRef } = {}) {
	const priceData = ref(null);
	const currency = ref('');
	const returnTypeDepartureDateAndFare = ref(null);

	const unwatch = watch([
		originRef,
		destinationRef,
		departureDateRef,
		paymentTypeRef,
		isOneWayRef,
	].filter(Boolean), async () => {
		
		if (!originRef.value?.code || !destinationRef.value?.code) return {};
		
		let apiData = null;
		
		try {
			apiData = await fetchLowFareAPI(originRef.value.code, destinationRef.value.code, departureDateRef.value, paymentTypeRef?.value, endpointUrl, isOneWayRef.value);
		} catch (e) {
			return e;
		}

		if (!_isEmpty(apiData)) {
			const processedPriceData = processPriceData(departureDateRef.value && !isOneWayRef.value ? apiData[0].returnDetail : apiData);
			priceData.value = processedPriceData;
			currency.value = apiData.length ? apiData[0].currency : '';

			if (departureDateRef.value && apiData[0].returnDetail) {
				returnTypeDepartureDateAndFare.value = apiData[0];
			}
		} else {
			priceData.value = null;
			currency.value = '';
			returnTypeDepartureDateAndFare.value = null;

		}
		
	});
	
	const cleanUp = () => {
		unwatch();
	};

	return {
		priceData,
		currency,
		returnTypeDepartureDateAndFare,
		cleanUp,
	};
}

function processPriceData (list) {
	if (typeof list === 'object' && _isEmpty(list)) return {};

	let data = {};

	list.forEach(({ dateOfDeparture, currency, totalFareAmount }) => {
		const day = dateOfDeparture.substring(0, 2);
		const month = dateOfDeparture.substring(2, 4);
		const year = `20${dateOfDeparture.substring(4, 6)}`;
		const date = new Date(year, month, day);

		data[`${year}-${month}-${day}`] = totalFareAmount;
	});

	return data;
}

