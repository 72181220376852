<script>
export default {
	name: 'CookiesNotification',
	editConfig: {
		emptyLabel: 'Cookies Notification',
		isEmpty (props) {
			return true;
		},
	},
	inheritAttrs: false,
};
</script>

<script setup>
import OverlayBtmSticky from '@/components/OverlayBtmSticky.vue';
import CTAButton from '@/aem-components/CTAButton.vue';
import ToggleSwitch from '@/components/form/ToggleSwitch.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import usePersistencyWithExpiry from '~/logic/composables/usePersistencyWithExpiry';
import { useGlobalCookieSettings } from '~/logic/global-state/cookie-settings.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { sendPageView } from '~/logic/adobe-analytic.js';
import { useAuthStore } from '~/logic/auth/auth-store.js';
import { waitFor } from '~/logic/helpers/utils.js';
import { useFireflyAuthStore } from '~/logic/auth/firefly-auth-store.js';

const { isEditorMode } = useGlobalAEMState();

const props = defineProps({
	cookie: { type: Object, default: () => ({}) },
	detailedCookie: { type: Object, default: () => ({}) },
	cookiesNotificationAPIURL: { type: String, default: '' },
	cookiesNotificationMAGAPIURL: { type: String, default: '' },
	cookiesNotificationMHHAPIURL: { type: String, default: '' },
	cookiesNotificationFireflyAPIURL: { type: String, default: '' },
	cookiesNotificationAmalAPIURL: { type: String, default: '' },
});

const hasUserDecidedSettings = ref(false);

const isEnabledCookiePerformanceModel = ref(true);
const isEnabledCookiePreferencesModel = ref(props.detailedCookie.isPreferencesCookiesToggleOn);
const isEnabledCookieStatisticsModel = ref(props.detailedCookie.isStatisticsCookiesToggleOn);
const isEnabledCookieMarketingModel = ref(props.detailedCookie.isMarketingCookiesToggleOn);
const isCustomiseCookies = ref(false);

const {
	adobeAnalyticJsUrl,
	persistency,
	isEnabledCookiePreferences,
	isEnabledCookieStatistics,
	isEnabledCookieMarketing,
	initAdobeAnalytic,
} = useGlobalCookieSettings();

const siteName = window.siteName;

const apiUrlMap = {
	MH: props.cookiesNotificationAPIURL,
	MAG: props.cookiesNotificationMAGAPIURL,
	MHH: props.cookiesNotificationMHHAPIURL,
	firefly: props.cookiesNotificationFireflyAPIURL,
	amal: props.cookiesNotificationAmalAPIURL,
};

// eslint-disable-next-line
adobeAnalyticJsUrl.value = apiUrlMap[siteName] || '';


const { writeLocalStorage } = usePersistencyWithExpiry('cookies-notification:settings-decided', {
	expiryDuration: 7.776e+9, // <-- 90 days
	refs: {
		hasUserDecidedSettings,
	},
});

const saveCookiesPersistent = (isAcceptAllCookies) => {

	hasUserDecidedSettings.value = true;
	
	if (isAcceptAllCookies) {
		isEnabledCookiePreferencesModel.value = props.detailedCookie.isPreferencesCookiesHide ? false : true;
		isEnabledCookieStatisticsModel.value = props.detailedCookie.isStatisticsCookiesHide ? false : true;
		isEnabledCookieMarketingModel.value = props.detailedCookie.isMarketingCookiesHide ? false : true;
	}
	
	writeLocalStorage({
		hasUserDecidedSettings: hasUserDecidedSettings.value,
	});
	
	isEnabledCookiePreferences.value = props.detailedCookie.isPreferencesCookiesHide ? false : isEnabledCookiePreferencesModel.value;
	isEnabledCookieStatistics.value = props.detailedCookie.isStatisticsCookiesHide ? false : isEnabledCookieStatisticsModel.value;
	isEnabledCookieMarketing.value = props.detailedCookie.isMarketingCookiesHide ? false : isEnabledCookieMarketingModel.value;
	
	// console.log('isEnabledCookiePreferencesModel.value = ', isEnabledCookiePreferencesModel.value);
	// console.log('isEnabledCookieStatisticsModel.value = ', isEnabledCookieStatisticsModel.value);
	// console.log('isEnabledCookieMarketingModel.value = ', isEnabledCookieMarketingModel.value);
	
	persistency.writeLocalStorage({
		...(props.detailedCookie.isPreferencesCookiesHide ? {
			isEnabledCookiePreferences: false,
		} : {
			isEnabledCookiePreferences: isEnabledCookiePreferencesModel.value,
		}),
		...(props.detailedCookie.isMarketingCookiesHide ? {
			isEnabledCookieMarketing: false,
		} : {
			isEnabledCookieMarketing: isEnabledCookieMarketingModel.value,
		}),
		...(props.detailedCookie.isStatisticsCookiesHide ? {
			isEnabledCookieStatistics: false,
		} : {
			isEnabledCookieStatistics: isEnabledCookieStatisticsModel.value,
		}),
	});
	
	// Accept statistics cookies, sendPageView function will be called and send to AA.
	if (isEnabledCookieStatisticsModel.value) {
		attemptToSendPageView();
	}
	
	attemptToRunAdobeConsent();
};

const attemptToRunAdobeConsent = async () => {
	if (window._satellite) {
		_satellite.track('adobe-consent');
	} else {
		const wait = waitFor(() => window._satellite);
		await wait.start();
		_satellite.track('adobe-consent');
	}
};

const attemptToSendPageView = async () => {
	const { isAuthStateReady } = (siteName === 'firefly' ? useFireflyAuthStore() : useAuthStore());
	if (isAuthStateReady.value) {
		sendPageView();
	} else {
		const wait = waitFor(() => !!isAuthStateReady.value);
		await wait.start();
		sendPageView();
	}
};

const isCookieConfigEmpty = computed(() => {
	return Object.keys(props.cookie).length === 0;
});

onMounted(() => {
	isEnabledCookiePreferencesModel.value = isEnabledCookiePreferences.value === null ? props.detailedCookie.isPreferencesCookiesToggleOn : false;
	isEnabledCookieStatisticsModel.value = isEnabledCookieStatistics.value === null ? props.detailedCookie.isStatisticsCookiesToggleOn : false;
	isEnabledCookieMarketingModel.value = isEnabledCookieMarketing.value === null ? props.detailedCookie.isMarketingCookiesToggleOn : false;
	
	// if (hasUserDecidedSettings.value && isEnabledCookieStatistics.value) {
	// 	initAdobeAnalytic();
	// }
	initAdobeAnalytic();
});
</script>



<template>
<div
	v-if="!isCookieConfigEmpty"
	class="CookiesNotification"
>
	<OverlayBtmSticky
		:isVisible="!hasUserDecidedSettings && !isEditorMode"
		:overlayWrapperAttrs="{
			class: isCustomiseCookies ? 'CookiesNotificationOverlayWrapper' : '',
		}"
		:overlayContainerAttrs="{
			class: !isCustomiseCookies && (siteName === 'MHH' || siteName === 'firefly') ? '!pb-6' : '',
		}"
		:overlayContentAttrs="{
			class: isCustomiseCookies && (siteName === 'MHH' || siteName === 'firefly') ? 'md:mr-3.5 mr-5' : 'mb-0 pb-2',
		}"
		class="CookiesNotificationBtmOverlaySticky"
		:backdropClosable="false"
		:data-use-theme="siteName"
		:enableFocusTrap="(siteName !== 'firefly' && siteName !== 'MHH')"
	>
		<div
			class="cookies-notification-wrapper"
		>
			<div class="generic-container cookie-generic-container">
				<h5 class="cookie-title">{{ props.cookie.cookieSettingsTitle }}</h5>
				
				<p
					v-html-sanitize="props.cookie.cookieSettingsDescription"
					class="cookie-description mt-4"
				></p>
				
				<div
					class="cooking-settings md:flex-col-reverse"
					:class="{
						'flex': !isCustomiseCookies,
						'!mt-8': isCustomiseCookies && (siteName === 'MHH' || siteName === 'firefly'),
					}"
				>
					<CTAButton
						v-if="!isCustomiseCookies"
						:ctaText="props.cookie.customiseCookiesButtonLabel"
						:sizeType="(isMobileViewport || (siteName === 'MHH' || siteName === 'firefly')) ? 'small' : 'large'"
						:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
						alignmentDesktop="right"
						containerClass="customise-cookies-cta font-semibold"
						styleType="secondary"
						:verticalSpacing="isMobileViewport ? 5 : 0"
						@click="isCustomiseCookies = true;"
					/>
					<CTAButton
						:ctaText="props.cookie.acceptAllCookiesButtonLabel"
						:sizeType="(isMobileViewport || (siteName === 'MHH' || siteName === 'firefly')) ? 'small' : 'large'"
						:ctaSizing="(isMobileViewport || (siteName === 'MHH' || siteName === 'firefly')) ? 'fluid' : 'auto'"
						alignmentDesktop="right"
						containerClass="cookie-accept-all-cta font-semibold"
						@click="saveCookiesPersistent(true)"
					/>
				</div>
				
				<template v-if="isCustomiseCookies">
					<div class="divider"></div>
					
					<div
						class="cookie-details-section flex flex-col"
					>
						<div class="performance-cookies">
							<ToggleSwitch
								v-model="isEnabledCookiePerformanceModel"
								name="performanceCookies"
								:disabled="true"
								:ariaLabel="props.detailedCookie.performanceCookiesTitle"
								:rootAttrs="{
									class: 'required-consent',
								}"
							>
								<div class="flex flex-col rtl:mr-4">
									<div class="flex flex-wrap gap-x-6 gap-y-2">
										<div>{{ props.detailedCookie.performanceCookiesTitle }}</div>
										<div class="cookie-switch-badge text-white uppercase px-2 py-1 rounded flex items-center">
											<span class="text-xs md:text-[10px]">{{ props.detailedCookie.performanceCookiesAlwaysActiveTagLabel }}</span>
										</div>
									</div>
									<template v-if="(siteName !== 'firefly' && siteName !== 'MHH')">
										<div class="mt-2 font-light">
											<p>{{ props.detailedCookie.performanceCookiesDescription }}</p>
										</div>
									</template>
								</div>
							</ToggleSwitch>
							
						</div>
						<div
							v-if="!props.detailedCookie.isPreferencesCookiesHide"
							class="preferences-cookies"
						>
							<ToggleSwitch
								v-model="isEnabledCookiePreferencesModel"
								name="preferenceCookies"
								:ariaLabel="props.detailedCookie.preferencesCookiesTitle"
							>
								<div class="flex flex-col rtl:mr-4">
									<div class="flex gap-6">
										<div>{{ props.detailedCookie.preferencesCookiesTitle }}</div>
									</div>
									<template v-if="(siteName !== 'firefly' && siteName !== 'MHH')">
										<div class="mt-2 font-light">
											<p>{{ props.detailedCookie.preferencesCookiesDescription }}</p>
										</div>
									</template>
								</div>
							</ToggleSwitch>
						</div>
						<div
							v-if="!props.detailedCookie.isStatisticsCookiesHide"
							class="statistics-cookies"
						>
							<ToggleSwitch
								v-model="isEnabledCookieStatisticsModel"
								name="statisticsCookies"
								:ariaLabel="props.detailedCookie.statisticsCookiesTitle"
							>
								<div class="flex flex-col rtl:mr-4">
									<div class="flex gap-6">
										<div>{{ props.detailedCookie.statisticsCookiesTitle }}</div>
									</div>
									<template v-if="(siteName !== 'firefly' && siteName !== 'MHH')">
										<div class="mt-2 font-light">
											<p>{{ props.detailedCookie.statisticsCookiesDescription }}</p>
										</div>
									</template>
								</div>
							</ToggleSwitch>
						</div>
						<div
							v-if="!props.detailedCookie.isMarketingCookiesHide"
							class="marketing-cookies"
						>
							<ToggleSwitch
								v-model="isEnabledCookieMarketingModel"
								name="marketingCookies"
								:ariaLabel="props.detailedCookie.marketingCookiesTitle"
							>
								<div class="flex flex-col rtl:mr-4">
									<div class="flex gap-6">
										<div>{{ props.detailedCookie.marketingCookiesTitle }}</div>
									</div>
									<template v-if="(siteName !== 'firefly' && siteName !== 'MHH')">
										<div class="mt-2 font-light">
											<p>{{ props.detailedCookie.marketingCookiesDescription }}</p>
										</div>
									</template>
								</div>
							</ToggleSwitch>
						</div>
					</div>
				</template>
			</div>
			
			<div
				v-if="isCustomiseCookies"
				class="cookies-notification-bottom >md:px-2"
			>
				<div class="cta-wrapper generic-container flex md:flex-col-reverse >md:items-center justify-end gap-x-8 md:gap-y-4">
					<CTAButton
						:ctaText="props.detailedCookie.hideCookiesPreferenceButtonLabel"
						:sizeType="(isMobileViewport || (siteName === 'MHH' || siteName === 'firefly')) ? 'small' : 'large'"
						:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
						styleType="text-primary"
						containerClass="hideCookiesPreferenceBtn"
						@click="isCustomiseCookies = false"
					/>
					<CTAButton
						:ctaText="props.detailedCookie.savePreferenceButtonLabel"
						:sizeType="(isMobileViewport || (siteName === 'MHH' || siteName === 'firefly')) ? 'small' : 'large'"
						:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
						containerClass="font-semibold"
						@click="saveCookiesPersistent(false)"
					/>
				</div>
			</div>
		</div>
		
	</OverlayBtmSticky>
</div>
</template>

<style lang="scss">
@use '~/styles/partials/_var.scss';
@use 'sass:color';

.CookiesNotificationBtmOverlaySticky {
	.CookiesNotificationOverlayWrapper {
		padding-bottom: 116px;
		
		@media #{var.$query-max-md} {
			padding-bottom: 130px;
		}
	}
}

[data-use-theme="MHH"] {
	.CookiesNotificationOverlayWrapper {
		@media #{var.$query-max-md} {
			padding-bottom: 155px;
		}
		
		.styled-scrollbar {
			&::-webkit-scrollbar-thumb {
				background-color: var(--secondary-mhh-teal-light);
			}
			
			&::-webkit-scrollbar:vertical {
				width: 4px;
			}
		}
	}
	
	&.CookiesNotificationBtmOverlaySticky {
		
		
		@media #{var.$query-min-md} {
			background-color: transparent;
			justify-content: end;
			
			// To allow users to click elements during cookies notification appears.
			pointer-events: none;
		}
		
		.overlay-container {
			background-color: var(--neutral-grey-extralight);
			
			@media #{var.$query-min-md} {
				box-shadow: 0px 6px 15px #70707055;
				max-width: 385px;
				margin-right: 24px;
				
				// To allow child of parent able to click.
				pointer-events: auto;
			}
		}
		
		.overlay-line {
			padding-bottom: 14px;
		}
	}
};

[data-use-theme="firefly"] {
	.CookiesNotificationOverlayWrapper {
		@media #{var.$query-max-md} {
			padding-bottom: 155px;
		}
		
		.styled-scrollbar {
			&::-webkit-scrollbar-thumb {
				background-color: var(--primary-firefly-orange-base);
			}
			
			&::-webkit-scrollbar:vertical {
				width: 4px;
			}
		}
	}
	
	&.CookiesNotificationBtmOverlaySticky {
		
		
		@media #{var.$query-min-md} {
			background-color: #{color.adjust(var.$primary-firefly-black-base, $alpha: -0.2)};
			justify-content: end;
		}
		
		.overlay-container {
			background-color: var(--secondary-firefly-orange-ultralight);
			
			@media #{var.$query-min-md} {
				box-shadow: 0px 6px 15px #70707055;
				max-width: 385px;
				margin-right: 24px;
			}
		}

		.overlay-wrapper {
			background-color: var(--secondary-firefly-orange-ultralight);
		}
		
		.overlay-line {
			padding-bottom: 14px;
		}
	}
};
</style>

<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

.CookiesNotification {

}

.divider {
	background-color: var(--neutral-grey-base);
	height: 1px;
	width: 100%;
	@apply my-8;
}

.cookie-description {
	
	:deep(a) {
		color: var(--primary-blue-base);
		@apply font-semibold;
		
		&:hover {
			color: var(--primary-blue-light);
		}
	}
}

.cookie-details-section {
	row-gap: 2rem;
	padding-bottom: 2rem;
	
	.performance-cookies {
		:deep(.ToggleSwitch) {
			
			&.is-disabled {
				.label-after {
					color: var(--primary-black-base);
				}
			}
		}
	}
}

.cookies-notification-bottom {
	border-top: 1px solid var(--neutral-grey-base);
	@apply py-6;
	
	@media #{var.$query-max-md} {
		@apply pt-4 pb-6;
	}
	
	
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: var(--neutral-white-base);
}

:deep(.CTAButton.sizeType-text) {
	color: var(--primary-blue-base);
	@apply font-semibold;
	
	&:hover {
		color: var(--primary-blue-light);
	}
	.icon-arrow-right {
		display: none;
	}
}


.hideCookiesPreferenceBtn {
	:deep(.icon-arrow-right) {
			display: none;
		}
	
	&:hover {
		:deep(.icon-arrow-right) {
			@apply transform translate-x-0.5;
		}
	}
	
	html[dir="rtl"] & {
		:deep(.icon-arrow-right) {
			@apply transform -rotate-180;
		}
		
		&:hover {
			:deep(.icon-arrow-right) {
				@apply transform -rotate-180 -translate-x-0.5;
			}
		}
	}
}

.cooking-settings {
	margin-top: 32px;
	justify-content: flex-end;
	column-gap: 2rem;
}

.cookie-switch-badge {
	background-color: var(--primary-blue-base);
}

[data-use-theme="MHH"] {
	.cookies-notification-bottom {
		@media #{var.$query-min-md} {
			max-width: 385px;
			left: revert;
		}
	}
	
	.cookie-switch-badge {
		background-color: var(--secondary-mhh-teal-light);
	}
	
	.cookies-notification-bottom {
		padding-top: 2rem;
		padding-bottom: 2rem;
		background-color: var(--neutral-grey-extralight);
		
		@media #{var.$query-max-md} {
			padding-top: 1rem;
		}
	}
	
	.cookie-title {
		font-size: 18px;
	}
	
	.cookie-details-section {
		row-gap: 1.5rem;
		padding-bottom: 0.5rem;
	}
	
	.cookie-generic-container {
		padding-left: 24px;
		
		@media #{var.$query-max-md} {
			padding-left: 20px;
		}
	}
	
	.customise-cookies-cta {
		@media #{var.$query-max-md} {
			margin-top: 16px;
		}
	}
	
	.cooking-settings {
		column-gap: 1.5rem;
		justify-content: center;
		
		@media #{var.$query-max-md} {
			margin-top: 24px;
		}
	}
	
	.hideCookiesPreferenceBtn {
		:deep(.CTAButton) {
			font-size: 14px;
		}
	}
	
	.cta-wrapper {
		justify-content: space-evenly;
	}
}

[data-use-theme="firefly"] {

	.cookies-notification-wrapper {
		background-color: var(--secondary-firefly-orange-ultralight);
	}
	
	.cookies-notification-bottom {
		@media #{var.$query-min-md} {
			max-width: 385px;
			left: revert;
		}
	}
	
	.cookie-switch-badge {
		background-color: var(--primary-firefly-orange-base);
	}
	
	.cookies-notification-bottom {
		padding-top: 2rem;
		padding-bottom: 2rem;
		background-color: var(--secondary-firefly-orange-ultralight);
		
		@media #{var.$query-max-md} {
			padding-top: 1rem;
		}
	}
	
	.cookie-title {
		font-size: 18px;
	}
	
	.cookie-details-section {
		row-gap: 1.5rem;
		padding-bottom: 0.5rem;
	}
	
	.cookie-generic-container {
		padding-left: 24px;
		
		@media #{var.$query-max-md} {
			padding-left: 20px;
		}
	}
	
	.customise-cookies-cta {
		@media #{var.$query-max-md} {
			margin-top: 16px;
		}
	}
	
	.cooking-settings {
		column-gap: 1.5rem;
		justify-content: center;
		flex-direction: row-reverse;
		
		@media #{var.$query-max-md} {
			margin-top: 24px;
			flex-direction: column-reverse;
		}
	}
	
	.hideCookiesPreferenceBtn {
		:deep(.CTAButton) {
			font-size: 14px;
		}
	}
	
	.cta-wrapper {
		justify-content: space-evenly;
	}
}
</style>
