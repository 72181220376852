<script>
export default {
	name: 'FireflyCheckIn',
	inheritAttrs: false,
};
</script>

<script setup>
import yup from 'mh-yup';
import MobileSubpage from '~/components/BookingWidget/MobileSubpage.vue';
import useBookingWidgetSubpage from '~/logic/composables/useBookingWidgetSubpage.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import CTAButton from '~/aem-components/CTAButton.vue';
import TextField from '~/components/form/TextField.vue';
import ONDPicker from '~/components/BookingWidget/ONDPicker.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import Tooltip from '~/components/Tooltip.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import RadioButton from '~/components/form/RadioButton.vue';

const props = defineProps({
	isHideInDesktop: { type: Boolean, default: false },
	isHideInMobile: { type: Boolean, default: false },

	checkInDescription: { type: String, default: '' },
	checkInToolTipLabel: { type: String, default: '' },
	checkInToolTip: { type: String, default: '' },
	checkInNote: { type: String, default: '' },
	checkInButtonLabel: { type: String, default: '' },

	bookingVia: { type: Object, default: () => ({}) },
	bookingRef: { type: Object, default: () => ({}) },
	ond: { type: Object, default: () => ({}) },
	mobileLabels: { type: Object, default: () => ({}) },

	desktopCheckInAPIURL: { type: String, default: '' },
	mobileCheckInAPIURL: { type: String, default: '' },
	
	componentIdAA: { type: String, default: '' },
});
const emit = defineEmits([
	'update:active-tab',
]);

const rootEl = ref(null);
const formWrapper = ref(null);
const bretnFieldValue = ref('');
const bookingViaValue = ref('FY');
const ondValueFrom = ref(null);
const ondValueTo = ref(null);

const { isEditorMode } = useGlobalAEMState();


const { isMobileSubpageVisible } = useBookingWidgetSubpage({
	hashKey: 'check-in',
	rootEl,
});

const bretnFieldValidation = computed(() => {

	return yup
		.string()
		.required(props.bookingRef?.bretnMandatoryErrorMessage)
		.max(props.bookingRef?.bretnCharLimit, props.bookingRef?.bretnCharLimitErrorMessage);
	
});

const handleSubmitValid = (values, actions) => {
	console.log('✅ handleSubmitValid values = ', values);
	formSubmission(values);
};

const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
};

const formSubmission = (values) => {

	const params = {
		pnr: values.bretnField,
		airline: values.bookingVia,
		depart: values.locationFrom,
		arrive: values.locationTo,
	};
	
	const url = new URL(isMobileViewport.value ? props.mobileCheckInAPIURL : props.desktopCheckInAPIURL);
	
	Object.keys(params).forEach((key) => {
		url.searchParams.append(key, params[key]);
	});
	
	// Redirect to REFX Check-in
	window.location.href = url.toString();
	
};


const isBtnSubmitDisabled = computed(() => {
	const form = formWrapper.value;
	if (!form) return true;
	return (
		!form.values.locationFrom ||
		!form.values.locationTo ||
		!form.values.bretnField ||
		!form.values.bookingVia ||
		Object.keys(form.errors).length !== 0
	);
});

const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'check-in-tab',
});

registerQueryChange(async (query) => {
	if (!query) return;
	if (isMobileViewport.value) {
		isMobileSubpageVisible.value = true;
	}

	emit('update:active-tab', query.maintab);
});

</script>



<template>
<div
	ref="rootEl"
	class="FireflyCheckIn booking-widget-subcomponent"
	:class="{
		'>md:hidden': props.isHideInDesktop,
		'hidden >md:block': props.isHideInMobile,
		'isEditorMode': isEditorMode,
	}"
>
	<div class="generic-container">
		<h5 v-if="isEditorMode" class="title-editor-mode">
			<icon-fas-plane class="mr-2 fill-current inline-block" />
			<span>Booking Widget: Check In</span>
		</h5>

		<p v-html-sanitize="props.checkInDescription" class="mb-6"></p>
		<div
			v-if="props.checkInToolTipLabel && props.checkInToolTip"
			class="check-in-tooltip flex items-center mb-6"
		>
			<Tooltip>
				<template #default>
					<icon-fas-circle-question class="fill-secondary-firefly-orange-extradark" />
				</template>
				<template #mobile-title>
					{{ props.checkInToolTipLabel }}
				</template>
				<template #tooltip-content>
					<p v-html-sanitize="props.checkInToolTip"></p>
				</template>
			</Tooltip>
			<span class="ml-3">{{ props.checkInToolTipLabel }}</span>
		</div>

		<FormWrapper
			ref="formWrapper"
			v-slot="{ errors }"
			class="form-container"
			@submit-valid="handleSubmitValid"
			@submit-invalid="handleSubmitInvalid"
		>
			<div class="mb-5 flex gap-7">
				<p class="">{{ props.bookingVia?.bookingViaLabel }}</p>
				<RadioButton
					v-model="bookingViaValue"
					name="bookingVia"
					:items="[
						{ text: props.bookingVia?.radioButton1Label, value: 'FY' },
						{ text: props.bookingVia?.radioButton2Label, value: 'MH' },
					]"
					:layoutAttrs="{ class: '!flex' }"
				>
				</RadioButton>
			</div>
			<div class="flex gap-6 xl:gap-3 xl:flex-wrap lg:flex-col">
				<div class="flex-grow lg:w-full">
					<ONDPicker
						v-model:modelValueFrom="ondValueFrom"
						v-model:modelValueTo="ondValueTo"
						nameFrom="locationFrom"
						nameTo="locationTo"
						:requiredFrom="true"
						:requiredTo="true"
						:requiredErrorMsgFrom="props.ond?.fromSearchErrorMessage"
						:requiredErrorMsgTo="props.ond?.toSearchErrorMessage"
						:labelTextFrom="props.ond?.fromLabel"
						:labelTextTo="props.ond?.toLabel"
						:noResultsText="props.ond?.noResultMessage"
						ondListIdentifier="FLIGHT_SCHEDULE"
					>
						<template #from-picker-mobile-title>
							<p>{{ props.ond?.fromLabel }}</p>
						</template>
						<template #to-picker-mobile-title>
							<p>{{ props.ond?.toLabel }}</p>
						</template>
					</ONDPicker>
				</div>
				<div class="w-4/10 lg:w-full">
					<TextField
						v-model="bretnFieldValue"
						:placeholder="props.bookingRef?.bretnPlaceholder"
						:ariaLabel="props.bookingRef?.bretnLabel"
						:validation="bretnFieldValidation"
						:allowedKeys="/[A-Za-z0-9]/"
						name="bretnField"
						variant="booking-widget"
					>
						<template v-if="props.bookingRef?.bretnShortDescription" #short-description>
							<p
								v-if="!formWrapper?.errors['bretnField']"
								v-html-sanitize="props.bookingRef?.bretnShortDescription"
							></p>
						</template>
					</TextField>
				</div>
				<div class="w-[max-content] flex-none xl:w-full mt-1">
					<CTAButton
						v-aa="[
							{
								clickName: props.checkInButtonLabel,
								clickComponentType: 'Button',
								componentName: 'CheckIn',
								componentID: props.componentIdAA,
							},
							{
								name: props.checkInButtonLabel,
								type: 'exit',
							},
						]"
						:ctaText="props.checkInButtonLabel"
						:disabled="isBtnSubmitDisabled"
						:isSubmit="true"
						:sizeType="isMobileViewport ? 'small' : 'medium'"
						ctaSizing="fluid"
					/>
				</div>
			</div>

		</FormWrapper>

		<div
			v-if="props.checkInNote"
			class="flex mt-4"
		>
			<div class="rounded-xl bg-secondary-firefly-orange-ultralight flex-grow px-5 py-4 flex">
				<div class="basis-6 flex-none mr-3 rtl:ml-3 rtl:mr-0 top-0.5 relative">
					<icon-fas-circle-info class="fill-secondary-firefly-orange-extradark" aria-hidden="true" />
				</div>
				<div class="typo-body-text-2">
					<span v-html-sanitize="props.checkInNote"></span>
				</div>
			</div>
		</div>
	</div>
</div>
<MobileSubpage
	:isVisible="isMobileSubpageVisible"
	class="text-white"
	:backButtonLabel="props.mobileLabels?.backButton"
	@update:is-visible="isMobileSubpageVisible = $event"
>

	<template #banner-title>
		<h1 class="text-center">{{ props.mobileLabels?.bannerText }}</h1>
	</template>

	<template #default>
		<div class="generic-container my-4">
			<h5 v-if="isEditorMode" class="title-editor-mode">
				<icon-fas-plane class="mr-2 fill-current inline-block" />
				<span>Booking Widget: Check-in</span>
			</h5>

			<p v-html-sanitize="props.checkInDescription" class="mb-6"></p>
			<div
				v-if="props.checkInToolTipLabel && props.checkInToolTip"
				class="check-in-tooltip flex items-center mb-6"
			>
				<Tooltip>
					<template #default>
						<icon-fas-circle-question class="fill-secondary-firefly-orange-extradark" />
					</template>
					<template #mobile-title>
						{{ props.checkInToolTipLabel }}
					</template>
					<template #tooltip-content>
						<p v-html-sanitize="props.checkInToolTip"></p>
					</template>
				</Tooltip>
				<span class="ml-3">{{ props.checkInToolTipLabel }}</span>
			</div>

			<FormWrapper
				ref="formWrapper"
				v-slot="{ errors }"
				class="form-container"
				@submit-valid="handleSubmitValid"
				@submit-invalid="handleSubmitInvalid"
			>
				<div class="mb-5 >md:flex gap-7">
					<p class="md:mb-3">{{ props.bookingVia?.bookingViaLabel }}</p>
					<RadioButton
						v-model="bookingViaValue"
						name="bookingVia"
						:items="[
							{ text: props.bookingVia?.radioButton1Label, value: 'FY' },
							{ text: props.bookingVia?.radioButton2Label, value: 'MH' },
						]"
						:layoutAttrs="{ class: '!flex' }"
					>
					</RadioButton>
				</div>
				<div class="flex gap-6 xl:gap-3 xl:flex-wrap lg:flex-col">
					
					<div class="flex-grow lg:w-full">
						<ONDPicker
							v-model:modelValueFrom="ondValueFrom"
							v-model:modelValueTo="ondValueTo"
							nameFrom="locationFrom"
							nameTo="locationTo"
							:requiredFrom="true"
							:requiredTo="true"
							:requiredErrorMsgFrom="props.ond?.fromSearchErrorMessage"
							:requiredErrorMsgTo="props.ond?.toSearchErrorMessage"
							:labelTextFrom="props.ond?.fromLabel"
							:labelTextTo="props.ond?.toLabel"
							:noResultsText="props.ond?.noResultMessage"
							ondListIdentifier="FLIGHT_SCHEDULE"
						>
							<template #from-picker-mobile-title>
								<p>{{ props.ond?.fromLabel }}</p>
							</template>
							<template #to-picker-mobile-title>
								<p>{{ props.ond?.toLabel }}</p>
							</template>
						</ONDPicker>
					</div>
					<div class="w-4/10 lg:w-full">
						<TextField
							v-model="bretnFieldValue"
							:placeholder="props.bookingRef?.bretnPlaceholder"
							:ariaLabel="props.bookingRef?.bretnLabel"
							:validation="bretnFieldValidation"
							:allowedKeys="/[A-Za-z0-9]/"
							name="bretnField"
							variant="booking-widget"
						>
							<template v-if="props.bookingRef?.bretnShortDescription" #short-description>
								<p
									v-if="!formWrapper?.errors['bretnField']"
									v-html-sanitize="props.bookingRef?.bretnShortDescription"
								></p>
							</template>
						</TextField>
					</div>
					<div class="w-[max-content] flex-none xl:w-full mt-1">
						<CTAButton
							v-aa="[
								{
									clickName: props.checkInButtonLabel,
									clickComponentType: 'Button',
									componentName: 'CheckIn',
									componentID: props.componentIdAA,
								},
								{
									name: props.checkInButtonLabel,
									type: 'exit',
								},
							]"
							:ctaText="props.checkInButtonLabel"
							:disabled="isBtnSubmitDisabled"
							:isSubmit="true"
							:sizeType="isMobileViewport ? 'small' : 'medium'"
							ctaSizing="fluid"
						/>
					</div>
				</div>

			</FormWrapper>

			<div
				v-if="props.checkInNote"
				class="flex mt-4"
			>
				<div class="rounded-xl bg-secondary-firefly-orange-ultralight flex-grow px-5 py-4 flex">
					<div class="basis-6 flex-none mr-3 rtl:ml-3 rtl:mr-0 top-0.5 relative">
						<icon-fas-circle-info class="fill-secondary-firefly-orange-extradark" aria-hidden="true" />
					</div>
					<div class="typo-body-text-2">
						<span v-html-sanitize="props.checkInNote"></span>
					</div>
				</div>
			</div>
		</div>
	</template>

</MobileSubpage>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.FireflyCheckIn {
	
}

</style>
