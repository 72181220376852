<script>
export default {
	name: 'AppFooterAccordion',
	expose: [],
	inheritAttrs: false,
};
</script>

<script setup>
import TransitionSimpleSlideDown from '~/components/transition/TransitionSimpleSlideDown.vue';
import { breakpoints, isMobileViewport } from '~/logic/composables/breakpoints';

const isMobileAndTabletViewport = breakpoints.smaller('lg');

const props = defineProps({
	items: { type: Array, default: () => [] },
	index: { type: Number, default: 0 },
	currentActiveAccordion: { type: String, default: '' },
	componentIdAA: { type: String, default: '' },
	componentNameAA: { type: String, default: 'AppFooter' },
});

const emit = defineEmits([
	'update:currentActiveAccordion',
]);

const currentActiveAccordion = ref(null);
const isExpanded = ref(false);

const toggleAccordion = (item) => {
	if (item.titleLabel === currentActiveAccordion.value) {
		isExpanded.value = !isExpanded.value;
	} else {
		isExpanded.value = true;
	}
	currentActiveAccordion.value = item.titleLabel;
	emit('update:currentActiveAccordion', item.titleLabel);
};

const setCurrentAccordion = (value) => {
	currentActiveAccordion.value = value;
};

defineExpose({
	setCurrentAccordion,
});

</script>

<template>
<div class="accordion flex lg:flex-col">
	<div
		v-for="item in props.items"
		:key="item.titleCTALabel"
		class=">lg:w-1/4 flex flex-col gap-2"
		:name="item.titleLabel"
		role="group"
	>
		<template
			v-if="(isMobileViewport && !item.isHideInHamburgerMenu) || !isMobileViewport"
		>
			<div
				class="wrapper md:px-2 rounded-lg"
				:class="{
					'lg:bg-primary-blue-extralight/16': isExpanded && currentActiveAccordion === item.titleLabel,
				}"
			>
				<template v-if="isMobileAndTabletViewport && item.navigationMenuLevel2.length">
					<button
						v-aa="[
							{
								clickName: item.titleLabel,
								clickComponentType: 'Button',
								componentName: props.componentNameAA,
								componentID: props.componentIdAA,
							},
							{
								name: item.titleLabel,
								type: 'other',
							},
						]"
						type="button"
						class="accordion-title mobile lg:py-5 px-0 lg:border-b w-full rounded-none border-neutral-grey-base/20"
						@click.prevent="toggleAccordion(item)"
					>
						<p class="font-semibold lg:flex lg:justify-between lg:items-center">
							<span class="">{{ item.titleLabel }}</span>
							<icon-fas-minus
								v-if="isExpanded && currentActiveAccordion === item.titleLabel"
								class="icon-plus-minus fill-white inline-block >lg:hidden"
							/>
							<icon-fas-plus
								v-else
								class="icon-plus-minus fill-white inline-block >lg:hidden"
							/>
						</p>
					</button>
				</template>
				<template v-else>
					<div class="accordion-title desktop lg:py-5 lg:border-b border-neutral-grey-base/20">
						<AppHyperlink
							v-aa="[
								{
									clickName: item.titleLabel,
									clickComponentType: 'URL',
									componentName: props.componentNameAA,
									componentID: props.componentIdAA,
								},
								{
									name: item.titleLabel,
									type: item.isExternalLink ? 'exit' : 'other',
								},
							]"
							class="footer-top-link font-semibold lg:flex lg:justify-between"
							:href="item.titlePagePath"
							:target="item.isOpenNewTab ? '_blank' : '_self'"
							:showExternalLinkIcon="false"
						>
							<span class="text-base font-semibold footer-top-link-text">{{ item.titleLabel }}</span>
							<icon-far-arrow-up-right-from-square
								v-if="item.isExternalLink"
								class="external-link-icon fill-white inline-block ml-3 rtl:mr-3 text-base"
							/>
						</AppHyperlink>
					</div>
				</template>
				<!-- Accordion body -->
				<template v-if="isMobileAndTabletViewport">
					<!-- Mobile -->
					<TransitionSimpleSlideDown>
						<div
							v-if="isExpanded && currentActiveAccordion === item.titleLabel && item.navigationMenuLevel2.length"
							class="accordion-body overflow-hidden"
						>
							<div class="accordion-content">
								<!-- Mobile level 1 title CTA Label start -->
								<AppHyperlink
									v-if="item.titleCTALabel"
									v-aa="[
										{
											clickName: item.titleCTALabel,
											clickComponentType: 'URL',
											componentName: props.componentNameAA,
											componentID: props.componentIdAA,
										},
										{
											name: item.titleCTALabel,
											type: 'other',
										},
									]"
									class="footer-top-link lg:py-[15px] lg:px-4"
									:href="item.titlePagePath"
									:showExternalLinkIcon="false"
								>
									<span class="footer-top-link-text">{{ item.titleCTALabel }}</span>
									<icon-far-arrow-right class="external-link-icon fill-white inline-block >md:hidden ml-3 rtl:mr-3 transform rtl:-rotate-180 text-base" />
								</AppHyperlink>
								<!-- Mobile level 1 title CTA Label end -->

								<AppHyperlink
									v-for="(itemChild, itemChildIndex) in item.navigationMenuLevel2"
									:key="itemChildIndex"
									v-aa="[
										{
											clickName: itemChild.titleLabel,
											clickComponentType: 'URL',
											componentName: props.componentNameAA,
											componentID: props.componentIdAA,
										},
										{
											name: itemChild.titleLabel,
											type: itemChild.isExternalLink ? 'exit' : 'other',
										},
									]"
									class="footer-top-link lg:py-[15px] lg:px-4 >md:hover:underline"
									:href="itemChild.titlePagePath"
									:target="itemChild.isOpenNewTab ? '_blank' : '_self'"
									:showExternalLinkIcon="false"
								>
									<span class="footer-top-link-text">{{ itemChild.titleLabel }}</span>
									<icon-far-arrow-up-right-from-square
										v-if="itemChild.isExternalLink"
										class="external-link-icon fill-white inline-block ml-3 rtl:mr-3 text-base"
									/>
								</AppHyperlink>
								<a
									v-if="item.isHasMore && item.navigationMenuLevel2.length"
									v-aa="[
										{
											clickName: $t('More'),
											clickComponentType: 'URL',
											componentName: props.componentNameAA,
											componentID: props.componentIdAA,
										},
										{
											name: $t('More'),
											type: 'other',
										},
									]"
									class="footer-top-link lg:py-[15px] lg:px-4"
									:href="item.titlePagePath"
								>
									<span class="">{{ $t('More') }}</span>
									<span class="sr-only"> {{ item.titleLabel }}</span>
								</a>
							</div>
						</div>
					</TransitionSimpleSlideDown>
				</template>
				<template v-else>
					<!-- Desktop -->
					<AppHyperlink
						v-for="itemChild in item.navigationMenuLevel2"
						:key="itemChild.titleLabel"
						v-aa="[
							{
								clickName: itemChild.titleLabel,
								clickComponentType: 'URL',
								componentName: props.componentNameAA,
								componentID: props.componentIdAA,
							},
							{
								name: itemChild.titleLabel,
								type: itemChild.isExternalLink ? 'exit' : 'other',
							},
						]"
						class="footer-top-link"
						:href="itemChild.titlePagePath"
						:target="itemChild.isOpenNewTab ? '_blank' : '_self'"
						:showExternalLinkIcon="false"
					>
						<div class="text-sm footer-top-link-text">
							<span>{{ itemChild.titleLabel }}</span>
							<icon-far-arrow-up-right-from-square
								v-if="itemChild.isExternalLink"
								class="external-link-icon fill-white inline-block ml-3 rtl:mr-3 text-base"
							/>
						</div>

					</AppHyperlink>
					<AppHyperlink
						v-if="item.isHasMore && item.navigationMenuLevel2.length"
						v-aa="[
							{
								clickName: $t('More'),
								clickComponentType: 'URL',
								componentName: props.componentNameAA,
								componentID: props.componentIdAA,
							},
							{
								name: $t('More'),
								type: 'other',
							},
						]"
						class="footer-top-link"
						:href="item.titlePagePath"
						:showExternalLinkIcon="false"
					>
						<span class="text-sm footer-top-link-text">{{ $t('More') }}</span>
					</AppHyperlink>
				</template>
				<!-- Accordion body end -->
			</div>
		</template>
	</div>
</div>
</template>

<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

.footer-top-link {
	display: block;
	@apply hover:text-primary-blue-extralight text-neutral-white-base;

	.footer-top-link-text {
		vertical-align: middle;
		display: inline-flex;

		@media #{var.$query-max-md} {
			@apply text-base;
		}
	}

	&:not(:first-child) {
		@media #{var.$query-min-md} {
			@apply mt-3;
		}
	}

	&:hover {
		.footer-top-link-text {
			text-decoration: underline;
			@apply text-primary-blue-extralight;
		}

		.external-link-icon {
			@apply fill-primary-blue-extralight
		}
	}
}

</style>
